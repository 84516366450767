import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {API} from "aws-amplify";
import {Button, Col, Container, Row} from "react-bootstrap";
import {DatePicker, FormField, Tabs} from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import {useHistory} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiEye, mdiPencil} from "@mdi/js";
import {CircularProgress, Tooltip} from "@material-ui/core";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import "../assets/css/components/CheckpointView.css";
import {ACTIVITY_TYPE_ATTR_VALUES_UPDATE} from "./common/Constants"
import CheckpointReport from "./CheckpointReport";
import AttributeFilter from "./AttributeFilter";
import RaciTable from "./RaciTable";
import {withStyles} from "@material-ui/core/styles";
import {ReportService} from "../service/ReportService";

/**
 * Loading page at the moment is just the Navbar
 *
 * @param props properties needed to render the page
 * - mode: whether we are creating or editing or viewing
 * - processId: the process id that is selected
 * - user: the user who is logged in
 * - updateMessage: method used to update the checkpoint message
 * - responsibilityAttributeId: attribute id for responsibility
 * - roleAttributeId: attribute id for role
 * - attributes: list of all attributes for given process
 * @returns {JSX.Element}
 * @constructor
 */
export default function CheckpointView(props) {

    const mode = props.mode;
    const processId = props.process.processId;
    const process = props.process;
    const user = props.user;
    const updateMessage = props.updateMessage;
    const responsibilityAttributeId = props.responsibilityAttributeId;
    const roleAttributeId = props.roleAttributeId;
    const attributes = props.attributes;
    const [selectedValues, setSelectedValues] = useState({});
    const hiddenCriteria = ["Role", "Responsibility"];
    const [roleSortOrderMap,setRoleSortOrderMap] = useState({});
    const PRODUCT_FAMILY_TYPE_TEXT = "Product Family Type";

    // get ID from the URL
    let { id } = useParams();

    // store the checkpoint state
    const [checkpoint, setCheckpoint] = useState(null);

    // various fields that make up a checkpoint
    const maxDelegateRank = 2;
    const [active, setActive] = useState(true);
    const [checkpointDate, setCheckpointDate] = useState("");
    const [description, setDescription] = useState("");
    const [programName, setProgramName] = useState("");

    // RACI table fields
    const [selectedTabId, setSelectedTabId] = useState('');
    const [generatedTable, setGeneratedTable] = useState(false);
    const [tableVals, setTableVals] = useState({});
    const [loading, setLoading] = useState(false);
    const [dateError, setDateError] = useState("");
    const [programNameError, setProgramNameError] = useState("");
    const [page, setPage] = useState(1);
    const [validCombination,setValidCombination] = useState(true);

    // attribute editor for overrides
    const [overrideItems, setOverrideItems] = useState([]);
    const [checkpointOverridesAssignees, setCheckpointOverridesAssignees] = useState([]);
    const [checkpointOverridesDelegates, setCheckpointOverridesDelegates] = useState([]);
    // list of associated keys with events
    const [associatedKeys, setAssociatedKeys] = useState([]);

    // various maps and links that are used throughout the page
    const rankMap = {1: {label: "1st Rank", value: 1}, 2: {label: "All Ranks", value: 2}}
    const editLink = checkpoint ? `/checkpoints/${checkpoint.checkpointId}?mode=edit` : '';
    const viewLink = checkpoint ? `/checkpoints/${checkpoint.checkpointId}?mode=view` : '';
    const invalidCombinationError = "This is not a valid combination. Please select valid combination of above criteria.";

    const attributeIDNameMap = {}
    const attributeNameIdMap = {}

    Object.values(attributes).forEach(attribute =>  attributeIDNameMap[attribute.attributeId] = attribute.attributeName);
    Object.values(attributes).forEach(attribute =>  attributeNameIdMap[attribute.attributeName] = attribute.attributeId);

    let userPromises = [];

    // make header dynamic based on responsibilityAttribute values
    const raciHeader = responsibilityAttributeId.values ? responsibilityAttributeId.values.map(col => {
        return ({label: col, id: col})
    }) : []

    // set default tab to first value
    useEffect(() => {
        if (responsibilityAttributeId.values) {
            setSelectedTabId(responsibilityAttributeId.values[0])
        }
    }, [responsibilityAttributeId])

    // used to update url on save success
    const history = useHistory();

    // method that converts overrides from AUI component view to human readable view
    const overridesToList = (overrides) => {
        let overridesList = [];
        overrides.forEach(override => {
            if (override.value) {
                let newValues = override.value.replace(/\s/g, '').split(",")
                overridesList.push({role: override.role.id, responsibility: override.resp.id,
                    assignee: override.key, delegates:newValues})
            }
        })
        return overridesList
    }

    // method that converts overrides from human view to AUI view
    const listToOverrides = (overridesList) => {
        return overridesList.map(override => ({role: {id: override.role, label: override.role},
            resp: {id: override.responsibility, label: override.responsibility},
            key: override.assignee, value: override.delegates.join(", ")}))
    }

    // method that fetches the checkpoint based off of supplied id
    useEffect(()=> {
        if (mode !== "create") {
            API.get("ApproverMatrixAPI", `/checkpoints/${id}`, {})
                .then(response => {
                    setCheckpoint(response)
                })
        }
    }, [id, mode])

    // method that sets keys and criteria for given checkpoint
    // rank map and true false map are constants, no dependency is needed
    useEffect(() => {
        if (checkpoint) {
            setCheckpointDate(checkpoint.checkpointDate);
            setProgramName(checkpoint.programName);
            setDescription(checkpoint.description);
            setCheckpointOverridesAssignees(checkpoint.checkpointOverridesAssigneesRemove);
            setCheckpointOverridesDelegates(checkpoint.checkpointOverridesDelegatesRemove);
            setOverrideItems(listToOverrides(checkpoint.checkpointOverridesAdd));
            setActive(active);
            setSelectedValues(checkpoint.checkpointCriteria);
        } // eslint-disable-next-line
    }, [checkpoint])

    const getCSVSortOrder = () =>{
        const productFamilyTypeKey = attributeNameIdMap[PRODUCT_FAMILY_TYPE_TEXT];
        const productFamilyTypeValue = selectedValues[productFamilyTypeKey];
        ReportService.getSortOrderForProductFamilyTypeAndProcess(processId, productFamilyTypeValue).then(
            response => {
                let sortOrderMap = {};
                Object.values(response.downloadOrder).forEach(value =>  sortOrderMap[value.role.trim()] = value.sortOrder);
                setRoleSortOrderMap(sortOrderMap);
            }).catch(
            err => {
                updateMessage('Unable to fetch sort order for product family type - ' + productFamilyTypeValue);
                setLoading(false);
            }
        );
    };

    // method that is called when "Generate Table" is clicked. Updates criteria and pulls associated keys
    const generateTable = () => {
        getCSVSortOrder();
        if (Object.keys(selectedValues).length === 0) {
            updateMessage("Please select a value for at least one criteria.", "error");
        } else {
            let emptyCoreAttribute = false;
            Object.values(attributes).forEach(attribute => {
                if (attribute.coreAttribute && !hiddenCriteria.includes(attribute.attributeName)
                    && selectedValues[attribute.attributeId] === undefined) {
                    emptyCoreAttribute = true;
                }
            })
            if (emptyCoreAttribute) {
                updateMessage("Please select a value for each core attribute.", "error");
            } else {
                setGeneratedTable(false);
                setLoading(true);
                API.post("ApproverMatrixAPI", `/keys/criteria`, {body: {criteria: selectedValues, processId: processId}})
                    .then(response => {

                        if(response.keys.length === 0)
                        {
                            setValidCombination(false);
                        }
                        else{
                            setValidCombination(true);
                            setGeneratedTable(true);
                        }

                        setAssociatedKeys(response.keys);
                        setLoading(false);
                    });

            }
        }
    }



    const getCancelUrl = () => {
        return mode === "create" ? "/" : mode === "edit" ? `/checkpoints/${checkpoint.checkpointId}?mode=view` : undefined
    }

    const compareDates = (checkpointDate) => {
        let currentDate = new Date().setHours(0,0,0,0);
        checkpointDate = new Date(checkpointDate);
        checkpointDate.setDate(checkpointDate.getDate() + 1);
        checkpointDate.setHours(0,0,0,0);
        return currentDate < checkpointDate;
    }

    const startSaveCheckpoint = () =>{
        if (checkpointDate === "" || programName === ""){
            setDateError(checkpointDate === "" ? "Please select a date" : "");
            setProgramNameError(programName === "" ? "Please enter a Program Name" : "");
            updateMessage(programName === "" ?"Please provide Program Name for Checkpoint!":"Please provide Date for Checkpoint!", "error");
        }else{
            Promise.all(userPromises).then(response => {
                let attributeList = Object.values(attributes);
                attributeList.forEach(attr => {attr.alias = user.userAlias});
                let requestBody = {
                    body: {
                        processId: processId,
                        activityType: ACTIVITY_TYPE_ATTR_VALUES_UPDATE,
                        attributeValidation: true,
                        attributes: attributeList
                    }
                }
                userPromises.push(API.post("ApproverMatrixAPI", "/background-activity", requestBody)
                    .then(response => {
                        console.log(response);
                        if (response.existUnfinishedActivity || (!response.attributeValuesMatched)){
                            // abort save
                            console.log("cannot save checkpoint because of background activities or mismatched attribute values");
                            updateMessage("Unable to save Checkpoint because there are other admins editing this process! Please come back later and refresh page", "error");
                        }else{
                            saveCheckpoint();
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        updateMessage("Unable to save Checkpoint because we cannot validate background activity and attribute values!", "error");
                    }))
            })
        }
    }

    // method to save a checkpoint in the DB
    const saveCheckpoint = () => {
        // preset init values
        let checkpointKeys = associatedKeys.map(key => key.keyId);
        let checkpointObj = {
            "processId": processId,
            "maxDelegateRank": 2,
            "active": active,
            "alias": user.userAlias,
            "checkpointDate": checkpointDate,
            "checkpointOverridesAssigneesRemove": [...checkpointOverridesAssignees],
            "checkpointOverridesDelegatesRemove": [...checkpointOverridesDelegates],
            "checkpointKeys": checkpointKeys,
            "description": description,
            "programName": programName
        }


        // convert override items to a map
        const overrideList = overridesToList(overrideItems)

        // try to save all the users in overrides (if they are not there error is thrown)
        overrideList.forEach(override =>  {
            userPromises.push(API.post("ApproverMatrixAPI", `/users`, {body: {userAlias: override.assignee, alias: user.userAlias,devAdmin:false}}))
            override.delegates.forEach(delegate => {
                userPromises.push(API.post("ApproverMatrixAPI", `/users`, {body: {userAlias: delegate, alias: user.userAlias,devAdmin:false}}))
            })
        })

        // ensure all calls to API succeeded
        Promise.all(userPromises)
            .then(response => {
                // set checkpoint fields and id if editing
                checkpointObj["checkpointCriteria"] = selectedValues;
                checkpointObj["checkpointOverridesAdd"] = overrideList;
                checkpointObj["description"] = description;
                checkpointObj["programName"] = programName;
                checkpointObj["checkpointKeys"] = checkpointKeys;
                checkpointObj["checkpointOwner"] = mode === "create" ? user.userAlias : checkpoint.checkpointOwner;
                if (mode === "edit") {
                    checkpointObj["checkpointId"] = checkpoint.checkpointId;
                }
                // final call to save checkpoint (if success show message and move to view mode)
                API.post("ApproverMatrixAPI", `/checkpoints`, {body: checkpointObj})
                    .then(response => {
                        updateMessage("Saved checkpoint successfully!", "success")
                        history.push(`/checkpoints/${response.checkpointId}?mode=view`)
                    })
                    .catch(err => {
                        // on failure show error message
                        err?.response?.data?.errorMessage ?
                            updateMessage("Unable to save checkpoint! " + err.response.data.errorMessage, "error")
                            : updateMessage("There are too many keys. Please select more criteria.", "error")
                    })
            })
            .catch(err => {
                // show error message in case use promises did not go through
                err?.response?.data?.errorMessage ? updateMessage("Unable to save user! " + err.response.data.errorMessage, "error")
                    : updateMessage("Unknown error saving user.", "error")
            });
    }

    // style for save button tooltip
    const FormattedTooltip = withStyles({
        arrow: {
            color: "black",
        },
        tooltip: {
            fontSize: "11.5px",
            maxWidth: 400,
            textAlign: "center",
            backgroundColor: "black",
        },
    })(Tooltip);

    return (
        <Container className="pt-4">
            <Row className="justify-content-md-center mb-lg-5">
                <Col lg={4} id="checkpointPageTitle" className="d-flex justify-content-center">
                    {mode === "create" ? <h1>Create Checkpoint</h1> : null}
                    {mode === "edit" ? <React.Fragment>
                        <h1>Edit Checkpoint</h1>
                        {(user.devAdmin || checkpoint?.checkpointOwner === user.userAlias ||
                            process?.processAdmins?.includes(user.userAlias)) ?
                            <Link to={viewLink}>
                                <Tooltip title={<p className="h6 mb-0">View Checkpoint</p>}>
                                    <Icon className="ms-2" path={mdiEye} size={1.5} />
                                </Tooltip>
                            </Link>
                            : null}
                    </React.Fragment>: null}
                    {mode === "view" ? <React.Fragment>
                        <h1>View Checkpoint</h1>
                        {(user.devAdmin || checkpoint?.checkpointOwner === user.userAlias ||
                            process?.processAdmins?.includes(user.userAlias)) ?
                            <Link to={editLink}>
                                <Tooltip title={<p className="h6 mb-0">Edit Checkpoint</p>}>
                                    <Icon className="ms-2" path={mdiPencil} size={1.5} />
                                </Tooltip>
                            </Link>
                            :null}
                    </React.Fragment>: null}
                </Col>

            </Row>
            <Row className="justify-content-md-center mb-lg-5">
                <Row className="pb-2 d-flex justify-content-center">
                    <Col lg={2}>
                        <FormField
                            label="Program Name*">
                        </FormField>
                        <Input
                            id="programNameInputBox"
                            value={programName}
                            onChange={({detail}) => {
                                setProgramNameError("");
                                setProgramName(detail.value);
                            }}
                            disabled={mode !== "view" ? undefined : true}
                            type="text"
                        />
                        {mode !== "view" ? <FormField
                            errorText={programNameError}>
                        </FormField> : undefined}

                    </Col>
                    <Col lg={3} className="d-flex me-5 justify-content-center">
                        <FormField
                            label="Milestone Review Date*"
                        >
                            <DatePicker
                                id="checkPointDate"
                                onChange={({ detail }) => {
                                    setDateError("");
                                    setCheckpointDate(detail.value);}}
                                value={checkpointDate}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                        ? `, selected date is ${selectedDate}`
                                        : "")
                                }
                                isDateEnabled={date =>
                                    compareDates(date)
                                }
                                disabled={mode !== "view" ? undefined : true}
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                            />
                            {mode !== "view" ? <FormField
                                errorText={dateError}>
                            </FormField> : undefined}
                        </FormField>
                    </Col>
                    <Col lg={2}>
                        <FormField
                            label="Description">
                        </FormField>
                        <Input
                            id="descriptionInputBox"
                            value={description}
                            onChange={({detail}) => setDescription(detail.value)}
                            disabled={mode !== "view" ? undefined : true}
                            type="text"/>
                    </Col>
                    {mode !== "create" ?
                        <Col lg={2} className="d-flex justify-content-center">
                            <FormField
                                label="Mark as Inactive">
                                <Row>
                                    <Col lg={6}>
                                        <Checkbox
                                            onChange={() => setActive(false)}
                                            checked={!active}
                                            disabled={mode === "view"}>
                                            Y
                                        </Checkbox>
                                    </Col>
                                    <Col lg={6}>
                                        <Checkbox
                                            onChange={() => setActive(true)}
                                            checked={active}
                                            disabled={mode === "view"}>
                                            N
                                        </Checkbox>
                                    </Col>
                                </Row>
                            </FormField>
                        </Col> : false}
                </Row>
            </Row>
            <Row className="pt-4 pb-2 justify-content-md-center mb-lg-5">
                <Col lg={4} id="selectionCriteria" className="d-flex justify-content-center">
                    <h2>Selection Criteria</h2>
                </Col>
            </Row>
            {
                Object.values(attributes).map((attribute,index) => {
                    if (((mode === 'edit' && Object.keys(selectedValues).length !== 0) ||
                        (mode === 'view' && Object.keys(selectedValues).length !== 0) ||
                        (mode === 'create')) && !hiddenCriteria.includes(attribute.attributeName)){
                        let selectedValue = selectedValues[attribute.attributeId] ?
                            {attribute: attribute,
                                value: selectedValues[attribute.attributeId]} : undefined;
                        return <AttributeFilter setSelectedValues = {setSelectedValues}
                                                attribute = {attribute}
                                                selectedValues = {selectedValues}
                                                selectedValue = {selectedValue}
                                                index = {index}
                                                key = {index}
                                                mode={mode}/>
                    } else {
                        return undefined;
                    }

                })
            }
            {
                validCombination === false?
                    <Row id= "username-filter-error-row" className="justify-content-md-center ">
                        <Col lg={3} className=" d-flex px-2 mx-2 d-flex justify-content-center ">
                            <FormField id= "username-filter-error-label"
                                       className="d-flex mx-4"
                                       errorText={invalidCombinationError} />
                        </Col>
                    </Row>:null
            }
            <Row className="pt-5 justify-content-md-center">
                <Col lg={3} className="d-flex justify-content-center">
                    <Button id="generateResponsibilityMatrixButton"
                            className="btn-success checkpoint-button" onClick={() => generateTable()}>Generate Responsibility Matrix</Button>
                </Col>
            </Row>

            {
                loading ?
                    <Row id="checkpoint-spinner-row" className="justify-content-md-center" >
                        <Col lg={4} className="mt-5 d-flex justify-content-center">
                            <CircularProgress id="checkpoint-spinner-" className="ms-3 mb-1" size={40}/>
                            <FormField id="checkpoint-spinner-label"className="pt-3 px-2 fw-bold"></FormField>
                        </Col>
                    </Row>:undefined
            }

            {generatedTable && validCombination ?
                <React.Fragment>
                    <Row className="justify-content-md-center">
                        <Col lg={8} className="d-flex justify-content-center">
                            <Tabs
                                activeTabId={selectedTabId}
                                onChange={(Checkpoint) => {
                                    setSelectedTabId(Checkpoint.detail.activeTabId);
                                    setPage(1);
                                }}
                                tabs={raciHeader}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{span: 8, offset: 2}}>
                            <RaciTable mode={mode} associatedKeys={associatedKeys} generatedTable={generatedTable}
                                       maxDelegateRank={maxDelegateRank} loading={loading}
                                       selectedTabId={selectedTabId}
                                       roleAttributeId={roleAttributeId} responsibilityAttributeId={responsibilityAttributeId}
                                       setTableVals={setTableVals} tableVals={tableVals} page={page} setPage={setPage}
                                       checkpointOverridesAssignees={checkpointOverridesAssignees}
                                       checkpointOverridesDelegates={checkpointOverridesDelegates}
                                       setCheckpointOverridesAssignees={setCheckpointOverridesAssignees}
                                       setCheckpointOverridesDelegates={setCheckpointOverridesDelegates}/>
                        </Col>
                    </Row>

                    <CheckpointReport   responsibilityAttributeId={responsibilityAttributeId} tableVals={tableVals} overridesToList={overridesToList}
                                        overrideItems={overrideItems} maxDelegateRank={maxDelegateRank}
                                        rankMap={rankMap}
                                        checkpointDate ={checkpointDate}
                                        programName = {programName}
                                        selectedValues = {selectedValues}
                                        attributes = {attributes}
                                        roleAttributeId={roleAttributeId}
                                        roleSortOrderMap={roleSortOrderMap}
                                        updateMessage={updateMessage}
                                        user={user}
                                        processId={processId}
                    />
                </React.Fragment>: null}
            {mode !== "view" && generatedTable && validCombination ?
                <Row className="justify-content-md-center">
                    <Col lg={4} className="d-flex justify-content-center">
                        <Col lg={2} className="m-5">
                            <FormattedTooltip arrow title="Clicking on Save finalizes the checkpoint and ensures the meeting owner gets a reminder 2 days prior to the meeting date.">
                                <Button  id="saveButton"
                                         onClick={() => startSaveCheckpoint()}
                                         className="p-2 btn-primary process-button">Save</Button>
                            </FormattedTooltip>
                        </Col>
                        <Col lg={2} className="m-5">
                            <Link to={getCancelUrl()}>
                                <Button
                                    id="cancelButton"
                                    variant="normal"
                                    className="p-2 btn-outline-secondary process-button">Cancel</Button>
                            </Link>
                        </Col>
                    </Col>

                </Row> : null}
        </Container>
    )
}