import React, {useState} from "react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {Pagination, Table, Header, Link, TextFilter, Box, Container, Button, SpaceBetween} from "@amzn/awsui-components-react";
import {CSVLink} from 'react-csv';
import {TableEmptyState} from "../config/TableEmptyState";
import {TableNoMatchState} from "../config/TableNoMatchState";
import {Preferences} from "../config/Preferences";
import {
    getFilterCounterText,
    PAGE_SIZE_OPTIONS
} from "../config/KeysTableConfig";

export default function FilteredKeysTable(props) {
    const relevantKeys = props.relevantKeys;
    const attributes = props.attributes;
    const enableExport = props.enableExport;
    const enableImport = props.enableImport;
    const loading = props.loading;
    const elementId = props.elementId;
    const [ reloadExportData, setReloadExportData ] = useState(true);
    const [ requestedCsvData, setRequestedCsvData ] = useState([]);

    const phonetoolLinkURL = "https://phonetool.amazon.com/users";

    const NOT_AVAILABLE_TEXT = "Not Available";
    const NOT_APPLICABLE_TEXT = "Not Applicable";
    const DUMMY_VALUES = new Set([NOT_APPLICABLE_TEXT,NOT_AVAILABLE_TEXT]);

    const columnDefinitions = [
        {id: "assignee", header: "Assignee", width: 210,minWidth: 210,maxWidth: 210,sortingField: "assignee", cell: e => (!DUMMY_VALUES.has(e.assignee) && e.assignee !== '') ? setNameDisplay(e, 'assignee') : e.assignee},
        {id: "delegate1", header: "Delegate 1", width: 210,minWidth: 210,maxWidth: 210,sortingField: "delegate1", cell: e => (e.delegate1 !== undefined && !DUMMY_VALUES.has(e.delegate1) && e.delegate1 !== '') ? setNameDisplay(e, 'delegate1') : ''},
        {id: "delegate2", header: "Delegate 2", width: 210,minWidth: 210,maxWidth: 210,sortingField: "delegate2", cell: e => (e.delegate2 !== undefined && !DUMMY_VALUES.has(e.delegate2) && e.delegate2 !== '') ? setNameDisplay(e, 'delegate2') : ''}
    ];

    const columnOptions = [
        { id: 'assignee', label: 'Assignee' },
        { id: 'delegate1', label: 'Delegate 1'},
        { id: 'delegate2', label: 'Delegate 2'},
    ];

    const csvHeaders = [
        {label: "Key Id", key: "keyId"},
        {label: "Assignee Alias", key: "assignee"},
        {label: "Assignee Name", key: "assigneeName"},
        {label: "Delegate 1 Alias", key: "delegate1"},
        {label: "Delegate 1 Name", key: "delegate1Name"},
        {label: "Delegate 2 Alias", key: "delegate2"},
        {label: "Delegate 2 Name", key: "delegate2Name"}
    ];

    const allFields = ['assignee', 'delegate1', 'delegate2'];

    Object.values(attributes).forEach((attribute) =>{
        allFields.push(attribute.attributeName.toLowerCase());
        columnDefinitions.push({id: attribute.attributeName.toLowerCase(), header: attribute.attributeName, width: 210,minWidth: 210,maxWidth: 210,sortingField: attribute.attributeName.toLowerCase(), cell: e => e[attribute.attributeName.toLowerCase()]});
        columnOptions.push({id: attribute.attributeName.toLowerCase(), label: attribute.attributeName});
        csvHeaders.push({label: attribute.attributeName, key: attribute.attributeName.toLowerCase()});
    });

    const [ preferences, setPreferences ] = useState({
        pageSize: 100,
        wrapLines: false,
        visibleContent: allFields,
        custom: 'table'
    });

    const visibleContentOptions = [
        {
            label: 'Main Key properties',
            options: columnOptions
        }
    ];

    const filterOptions = {
        empty: <TableEmptyState resourceName="Keys"/>,
        noMatch: <TableNoMatchState onClearFilter={clearFilter} />,
        filteringFunction: (item, filteringText) => {
            const searchableText = filteringText.toLowerCase();
            let matched = false;
            if (item.assigneeName !== undefined) {
                matched = matched || item.assigneeName.toLowerCase().includes(searchableText);
            }
            if (item.delegate1Name !== undefined) {
                matched = matched || item.delegate1Name.toLowerCase().includes(searchableText);
            }
            if (item.delegate2Name !== undefined) {
                matched = matched || item.delegate2Name.toLowerCase().includes(searchableText);
            }
            for (const field of allFields) {
                if (item[field] !== undefined) {
                    matched = matched || item[field].toLowerCase().includes(searchableText);
                }
            }

            return matched;
        }
    };

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        relevantKeys,
        {
            filtering: filterOptions,
            pagination: { pageSize: preferences.pageSize },
            sorting: {}
        }
    );

    function setNameDisplay(e, userFieldName) {
        if (e[userFieldName + 'Active'] !== undefined && e[userFieldName + 'Active']) {
            return <Link external href={`${phonetoolLinkURL}/${e[userFieldName]}`}>{e[userFieldName + 'Name']}</Link>;
        } else {
            return e[userFieldName + 'Name'] + ' (Inactive)';
        }
    }

    function clearFilter() {
        actions.setFiltering('');
    }

    const createExportData = (event, done) => {
        if (reloadExportData) {
            setReloadExportData(false);
            setRequestedCsvData(relevantKeys);
            done();
        }
    };

    const onFilterChange = (detail) => {
        filterProps.onChange(detail);
        setReloadExportData(true);
    };

    return (
         <Container className="py-2 my-xxl-5">
            <Table
                {...collectionProps}
                id={elementId+"filtered-keys-table"}
                resizableColumns={true}
                pagination={
                    <Pagination
                        id={elementId +"pagination"}
                        {...paginationProps}
                        ariaLabels={{
                            nextPageLabel: "Next page",
                            previousPageLabel: "Previous page",
                            pageLabel: pageNumber =>
                                `Page ${pageNumber} of all pages`
                        }}
                    />
                }
                header={
                    <Header counter={ '(' + relevantKeys.length.toString() + ')' }
                            actions={
                                <SpaceBetween size='xs' direction='horizontal'>
                                    {enableExport &&
                                    <Button id={elementId+"export-keys-data-button"} variant="primary">
                                        <CSVLink
                                            headers={csvHeaders}
                                            data={requestedCsvData}
                                            filename={'Keys.csv'}
                                            asyncOnClick={true}
                                            onClick={createExportData}
                                                >
                                                Export Keys Data
                                        </CSVLink>
                                    </Button>}
                                    {enableImport &&
                                    <Button id={elementId+"import-keys-button"} variant="primary" href="/import-key-csv">
                                        Import Keys from CSV
                                    </Button>}
                                </SpaceBetween>
                            }
                    >
                        Matching Results
                    </Header>
                }
                loadingText="Loading keys"
                loading={loading}
                columnDefinitions={columnDefinitions}
                items={items}
                visibleColumns={preferences.visibleContent}
                wrapLines={preferences.wrapLines}
                empty={
                    <Box textAlign="center" color="inherit" id="no-request-error-box">
                        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                            You don’t have any keys that you administer.
                        </Box>
                    </Box>
                }
                filter={
                    <TextFilter
                        {...filterProps}
                        id={elementId+"table-search"}
                        onChange={onFilterChange}
                        filteringAriaLabel="Filter keys"
                        filteringPlaceholder="Drilldown search for matching responsibility matrix"
                        countText={getFilterCounterText(filteredItemsCount !== undefined ? filteredItemsCount : 0)}
                    />
                }
                preferences={
                    <Preferences
                        elementId={elementId+"filtered-keys-table-"}
                        preferences={preferences}
                        setPreferences={setPreferences}
                        disabled={false}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        visibleContentOptions={visibleContentOptions}
                    />
                }
            />
         </Container>
    );


}