import React, {useState,useEffect} from "react";
import {FormField, Input} from "@amzn/awsui-components-react";
import {Button, Row} from "react-bootstrap";
import "../assets/css/components/Keys.css";
import {UserService} from "../service/UserService";

export default function KeyDelegates(props) {
    const [delegate,setDelegate] = useState(props.delegate);
    const setDelegates = props.setDelegates;
    const delegates = props.delegates;
    const index = props.index;
    const setValidDelegateList = props.setValidDelegateList;
    const validDelegateList =props.validDelegateList;

    const [name,setName] = useState("");
    const [email,setEmail]= useState("");
    const [aliasError, setAliasError] = useState("");
    const [enableValidateButton, setEnableValidateButton] = useState(true);
    const [displayNameAndEmail,setDisplayNameAndEmail] = useState(false)

    /**
     * useEffect hook to update the selected values for the attribute
     */
     useEffect(() => {
        setDelegate(props.delegate);
    }, [props.delegate]);

    const updateDelegateAlias = function (val){
        setDelegate({
            confirmationStatus:"CONFIRMED",
            alias: val,
            rank: delegate.rank,
            name:""
        });

        let keyDelegates = delegates;
        keyDelegates[index] = {
            confirmationStatus:"CONFIRMED",
            alias: val,
            rank: delegate.rank,
            name:""
        };

        setDelegates(keyDelegates);
        let validDelegates = validDelegateList;
        if(val !== "")
        {
            validDelegates[index] = false;
        }
        else{
            validDelegates[index] = true;
            setAliasError("");
        }
        setValidDelegateList(validDelegates);
        setEnableValidateButton(true);
        setEmail("");
        setDisplayNameAndEmail(false);
    };

    const updateDelegateRank = function(val){
        setDelegate({
            confirmationStatus:"CONFIRMED",
            alias: delegate.alias,
            rank: Number(val),
            name:name
        });
        let keyDelegates = delegates;
        keyDelegates[index] = {
            confirmationStatus:"CONFIRMED",
            alias: delegate.alias,
            rank: Number(val),
            name:name
        };
        setDelegates(keyDelegates);
    };

    const getNameFromPeopleAPI = function(){

        setName(delegate.alias);
        UserService.validateUser(delegate.alias.toLowerCase()).then(
            response => {
                if (!response.validUser)
                {
                    let validDelegates = validDelegateList;
                    validDelegates[index] = false;
                    setValidDelegateList(validDelegates);
                    setDisplayNameAndEmail(false);
                    setEnableValidateButton(true)
                    setAliasError(`Unable to find alias ${delegate.alias} in system.`);

                }
                else
                {
                    UserService.getUser(delegate.alias.toLowerCase()).then(response=>{
                        setName(response.userName);

                        setDelegate({
                            confirmationStatus:"CONFIRMED",
                            isActive: response.active,
                            alias: response.userAlias,
                            rank: delegate.rank,
                            name: response.userName
                        });
                        let keyDelegates = delegates;
                        keyDelegates[index] = {
                            confirmationStatus:"CONFIRMED",
                            isActive: response.active,
                            alias: response.userAlias,
                            rank: delegate.rank,
                            name:response.userName
                        };

                        setDelegates(keyDelegates);
                        let validDelegates = validDelegateList;
                        validDelegates[index] = true;
                        setValidDelegateList(validDelegates);

                        setEmail(response.userAlias +"@amazon.com");
                        setName(response.userName);
                        setAliasError("");
                        setEnableValidateButton(false)
                        setDisplayNameAndEmail(true);
                    })
                }
            })
            .catch(err => {
                let validDelegates = validDelegateList;
                validDelegates[index] = false;
                setValidDelegateList(validDelegates);
                setDisplayNameAndEmail(false);
                setEnableValidateButton(true)
                setAliasError(`Unable to find alias ${delegate.alias} in system.`);
            });
    }
    return (
        <Row  className="p-2 d-flex mb-3 mx-3 justify-content-md-center  row-control" style={{size:500}}>
            <div id={"delegate-div" + index} className="p-2 d-flex justify-content-lg-left border border-dark border-primary keys-delegate-section-div">

                <FormField id={"delegate-alias-label" + index} className="pt-3 px-4 fw-bold justify-content-end delegate-property-label" label="Alias: " />

                <Input id={"delegateAliasInputBox-" + index}
                       className="pt-2 px-4 justify-content-md-left"
                       type='text'
                       value={delegate.alias}
                       onChange={({detail})=>{ updateDelegateAlias(detail.value.trim())}}/>

                {
                    aliasError !== "" ?

                        <FormField id= {"username-filter-error-label-" + index}
                                   className="pt-2 px-2 fw-bold error-label"
                                   errorText={aliasError} />

                        :undefined
                }

                {
                    displayNameAndEmail?
                        <>

                                <FormField  className="pt-3 px-1 justify-content-lg-end delegate-property-label" label="Name: " />
                                <FormField  id={"delegateNameLabel-" +index} className="pt-3 px-2 justify-content-lg-left name-label" label={delegate.name ? delegate.name : name}/>

                                <FormField  className="pt-3 px-1 justify-content-lg-end delegate-property-label" label="Email: "/>
                                <FormField id={"delegateEmailLabel-" +index} className="pt-3  px-2 justify-content-lg-left email-label" label={delegate.alias ? delegate.alias + "@amazon.com" : email}/>

                        </>
                        : undefined

                }

                    <FormField className="pt-3 px-2 justify-content-lg-end delegate-property-label"
                               label="Rank: "/>
                    <Input id={"delegateRankInputBox-" +index}
                           value={delegate.rank}
                           className="pt-2 px-2 justify-content-md-left"
                           type='text'
                           disabled = {props.disableRank}
                           onChange={({detail})=>{updateDelegateRank(detail.value.trim())}}/>

                    <Button id={"delegateVallidateButton-" +index}disabled={!enableValidateButton}
                            variant="normal"
                            className="mx-5 mt-2 pt-2 px-2 editButton keysButton"
                            onClick={() =>getNameFromPeopleAPI()
                            }>Validate </Button>

            </div>
        </Row>
    )

}