import React, {useState, useEffect} from "react";
import {FormField, Multiselect} from "@amzn/awsui-components-react";
import {Col, Row,Button} from "react-bootstrap";
import "../assets/css/components/AttributeFilter.css";

export default function KeyCreationMultiAttribute(props) {
    const [selectedValues,setSelectedValues] = useState(props.attribute.selectedValues);
    const [attribute,setAttribute] = useState(props.attribute);
    const keyOptions = props.keyOptions;
    const setKeyOptions = props.setKeyOptions;
    const index = props.index;
    const SELECT_ALL_LABEL = "Select All";

    /**
     * useEffect hook to update the selected values for the attribute
     */
    useEffect(() => {
        setSelectedValues(props.selectedValues);
    }, [props.selectedValues]);

    const updateSelectedValue = function (options) {

        //If the Select All option is selected, call the handleSelectAllValues()
        if(props.displaySelectAll) {
            for(let idx = 0; idx < options.length; idx++) {
                if(options[idx].label === SELECT_ALL_LABEL) {
                    handleSelectAllValues();
                    return;
                }
            }
        }

        setSelectedValues(options);
        setAttribute({
            label: attribute.label,
            value:attribute.value,
            attributeValues:attribute.attributeValues,
            selectedAttributes:options,
            coreAttribute:attribute.coreAttribute

        })

        let _options = keyOptions;
        _options[index] = {
            label: attribute.label,
            value:attribute.value,
            attributeValues:attribute.attributeValues,
            selectedAttributes:options,
            coreAttribute:attribute.coreAttribute

        };
        setKeyOptions(_options);
    };

    /**
     * Handler to select all the values for the current MultiSelect filter
     */
    const handleSelectAllValues = () => {
        //Set all the available values to reflect through chiclets, and selected in the drop-down
        setSelectedValues(attribute.attributeValues);
        //Update the parent components selected Values object
        let _options = keyOptions;
        _options[index] = {
            label: attribute.label,
            value:attribute.value,
            attributeValues:attribute.attributeValues,
            selectedAttributes:attribute.attributeValues,
            coreAttribute:attribute.coreAttribute
        };
        setKeyOptions(_options);
    };

    /**
     * Function to generate the drop down options.
     * Append the select all option at the beginning in the event of presence of props.displaySelectAll
     */
    const generateOptionsList = () => {
        if(props.displaySelectAll) {
            return [{label: SELECT_ALL_LABEL, value: SELECT_ALL_LABEL}, ...attribute.attributeValues];
        }
        return attribute.attributeValues;
    };

    return (
        <Row  className="p-3  mb-3 justify-content-md-center  row-control px-lg-4" key={ index}>
            <Col id={"filter-criteria-label-col-" +index} lg={2}
                 className={`d-flex  justify-content-left ${props.classNamePrefix}-multi-select-label-wrapper`}>
                <FormField id={"filter-criteria-label-" +index}  className="pt-2 px-2 fw-bold "
                           label={attribute.label}/>
            </Col>

            <Col id={"filter-criteria-values-multiselect-dropdown-col-" + index} lg={3}
                 className={`justify-content-left ${props.classNamePrefix}-multi-select-filter-wrapper`}>
                <Multiselect id={"attributeSelectDropdown-" + attribute.label.replace(/\s/g, "")}
                             placeholder="Not Specified"
                             options={generateOptionsList()}
                             className="pt-2"
                             selectedOptions={selectedValues}
                             onChange={({detail}) => updateSelectedValue(detail.selectedOptions)}
                             autoComplete={false}/>
            </Col>
            <Col id={"reset-filter-criteria-button-col-" + index} lg={1}
                 className={` py-1 justify-content-center ${props.classNamePrefix}-multi-select-reset-wrapper`}>
                <Button
                    id={"reset-filter-criteria-button-" + index}
                    variant="normal"
                    className="btn btn-primary m-1 reset-button reset-all-button-wrapper"
                    onClick={() =>   updateSelectedValue([])}
                    autoComplete={false}> Reset
                </Button>
            </Col>

        </Row>
    )
}