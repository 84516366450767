import React, {useEffect, useRef, useState,useCallback} from "react";
import {useHistory} from "react-router-dom";
import {Button, Col, Row,Container} from "react-bootstrap";
import {FormField, Input, Select, Box, Grid, Header, Alert} from "@amzn/awsui-components-react";
import {Container as DialogContainer} from "@amzn/awsui-components-react";
import FilteredKeysTable from "./FilteredKeysTable";
import "../assets/css/components/Process.css";
import "../assets/css/components/Keys.css";
import "../assets/css/components/AttributeFilter.css";
import MultiAttributeFilter from "./MultiAttributeFilter";
import RACIMatrixAttributeEdit from "./RACIMatrixAttributeEdit";
import {CircularProgress} from "@material-ui/core";
import {KeyService} from "../service/KeyService";
import {UserService} from "../service/UserService";
import {FilterService} from "../service/FilterService";
import {flattenKeys,validateNameCharacters} from "./common/KeyUtils";
import ConfirmationDialog from "./ConfirmationDialog";
import {mdiPlusCircleOutline} from "@mdi/js";
import Icon from "@mdi/react";
import AdditionalInfo from "./AdditionalInfo";
import { verifyUpdateReasonSelected } from "./common/Utils";
import {Amplify} from "aws-amplify";
import {ACTIVITY_TYPE_ATTR_VALUES_UPDATE} from "./common/Constants"


/**
 * Page that loads when you try to create a new process or edit an existing process
 *
 * @param props properties needed to render the page
 * - mode: whether we are creating or editing a processs
 * - processId: the process id that is selected ,empty for a new process
 * - user: the user who is logged in
 * - attributes: list of all attributes for given process
 * @returns {JSX.Element}
 * @constructor
 */
export default function BulkKeyEditView(props) {

    const attributes = props.attributes;
    const user =props.user;
    const process = props.process;
    const updateMessage = props.updateMessage;
    const [keys,setKeys] = useState([]);
    const [relevantKeys, setRelevantKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userFilter,setUserFilter] = useState(user.userAlias);
    const [aliasError, setAliasError] = useState("");
    const [generateTable,setGenerateTable] = useState(false);
    const [generateButton,setGenerateButton] = useState(true);
    const [activeUpdateButton, setActiveUpdateButton] = useState(true);
    const [generateRMLoading, setGenerateRMLoading] = useState(false);
    const [userType,setUserType] = useState({label: 'All', value: 'ALL'});
    const [selectedFilter,setSelectedFilter] = useState("");
    const [filterName, setFilterName] = useState("");
    const [filterNameError, setFilterNameError] = useState("");

    const [userLoaded,setUserLoaded] = useState(false);
    const [bulkEditStarted,setBulkEditStarted] = useState(false);
    const [keyOptions,setKeyOptions] = useState([]);
    const [filterOptions,setFilterOptions] = useState([]);

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const confirmationDialogContent = useRef(null);
    const [selectedBulkEditValues,setSelectedBulkEditValues] = useState([]);
    const [selectedActions,setSelectedActions] = useState([]);
    const [bulkEditValidateErrors,setBulkEditValidateErrors] = useState([]);
    const [bulkEditValidateError,setBulkEditValidateError] = useState(false);
    const [bulkEditUserNames,setBulkEditUserNames] = useState([]);
    const [keySelectionCriteria, setKeySelectionCriteria] = useState({});
    const [mandatoryAttributesMissing, setMandatoryAttributesMissing] = useState(false);
    const [criteriaOverflowError, setCriteriaOverflowError] = useState(false);
    const [receiveNotification, setReceiveNotification] = useState(user.devAdmin ||process.processAdmins.includes(user.userAlias)?false:true);
    const [userStatus, setUserStatus]= useState({label: 'Both', value: 'BOTH'});

    const history = useHistory();

    const DELETE_DELEGATE_TEXT = "DELETE_DELEGATE";
    const REMOVE_TEXT = "Remove";
    const EDIT_TEXT = "Edit";
    const simTicketIdRef = useRef("");
    const notesRef = useRef("");
    const updateReasonRef = useRef("");
    const NOT_AVAILABLE_TEXT = "Not Available";
    const NOT_APPLICABLE_TEXT = "Not Applicable";
    const DUMMY_VALUES = new Set([NOT_APPLICABLE_TEXT,NOT_AVAILABLE_TEXT]);

    const CONFIRM_ACTION_CONSTANTS = {
        SAVE_FILTER: 'save filter',
        DELETE_FILTER: 'delete filter',
        BULK_UPDATE_KEYS:'bulk update',
        BULK_DELETE_KEYS:'bulk delete'
    };

    const MANDATORY_ATTRIBUTES = new Set(['Product Family Type', 'Product Line']);
    const MANDATORY_ATTRIBUTE_IDS =
        Object.entries(attributes)
            .filter(([key, value]) => MANDATORY_ATTRIBUTES.has(value.attributeName))
            .map(([key, value]) => value.attributeId);

    let bulkEditOptions = [];
    bulkEditOptions.push({label: 'Assignee', value: 'Assignee'});
    bulkEditOptions.push({label: 'Delegate 1', value: 'Delegate 1'});
    bulkEditOptions.push({label: 'Delegate 2', value: 'Delegate 2'});


    if (user.devAdmin  || process.processAdmins.includes(user.userAlias)) {
        Object.values(attributes).forEach(elem => {
            bulkEditOptions.push({label: elem.attributeName, value: elem.attributeName});
        });
    }

    const initialEdit =[];
    initialEdit.push(bulkEditOptions[0]);

    const [selectedBulkEditLabels,setSelectedBulkEditLabels] = useState(initialEdit);

    let userTypeOptions = [];
    userTypeOptions.push({label: 'Assignee', value: 'ASSIGNEE'});
    userTypeOptions.push({label: 'Delegate 1', value: 'DELEGATE1'});
    userTypeOptions.push({label: 'Delegate 2', value: 'DELEGATE2'});
    userTypeOptions.push({label: 'All', value: 'ALL'});

    let userStatusOptions = [];
    userStatusOptions.push({label: 'Active', value: 'ACTIVE'});
    userStatusOptions.push({label: 'Inactive', value: 'INACTIVE'});
    userStatusOptions.push({label: 'Both', value: 'BOTH'});


    let actionOptions = [];
    actionOptions.push({label: 'Remove', value: 'Remove'})
    actionOptions.push({label: 'Edit', value: 'Edit'})

    useEffect(()=>{
        setKeyOptions(
            Object.values(attributes).map(attribute => ({label: attribute.attributeName,
                value: attribute.attributeId,
                attributeValues:attribute.attributeValues.map(elem => ({label: elem, value: elem})).sort((a, b) =>
                    {
                        let l =  a.value.toLowerCase(), r = b.value.toLowerCase();

                        if (l < r) { return -1; }
                        if (l > r) { return 1; }
                        return 0;
                    }
                ),
                selectedAttributes:[],
                selectedValues:[],
                coreAttribute:attribute.coreAttribute
            })));
    },[attributes]);

    const attributeIDNameMap = {};
    Object.values(attributes).forEach(attribute =>  attributeIDNameMap[attribute.attributeName] = attribute.attributeId);

    useEffect(() => {
         setLoading(true);
         setUserLoaded(true);

        getAllFilters();

        if (user.userAlias !== undefined && user.userAlias !== null) {
            let aliasValue;
            if(DUMMY_VALUES.has(user.userAlias))
                aliasValue = user.userAlias;
            else aliasValue =user.userAlias.toLowerCase();
            KeyService.getMatchingKeysForUserAndProcess(aliasValue, process.processId,userType.value, userStatus.value).then(
                response => {
                    setKeys(response.keys);
                    setAliasError("");
                    setUserLoaded(true);
                    setLoading(false);
                    filterKeys(response.keys);
                    setGenerateButton(true);
                    setGenerateTable(false);
                }
            ).catch(err => {
                setGenerateTable(false);
                setGenerateButton(false);
                err?.response?.data?.errorMessage ?
                    updateMessage('Unable to fetch keys for user' + user.userAlias + err.response.data.errorMessage, "error")
                    : updateMessage("Unable to fetch keys for user." + user.userAlias, "error")
            });
        } else {
            KeyService.getMatchingKeysForCriteriaAndProcess(keySelectionCriteria, process.processId).then(
                response => {
                    setKeys(response.keys);
                    setAliasError("");
                    setUserLoaded(true);
                    setLoading(false);
                    setGenerateTable(false);
                    setRelevantKeys(flattenKeys(response.keys, attributes));
                    setGenerateTable(false);
                    setGenerateButton(true);
                    setLoading(false);
                }
            ).catch(err => {
                setGenerateButton(false);
                setUserLoaded(false);
                setGenerateTable(false);
                setLoading(false);
                err?.response?.data?.errorMessage ?
                    updateMessage('Unable to fetch keys for process' + process.processId + err.response.data.errorMessage, "error")
                    : updateMessage("Unable to fetch keys for process." + process.processId, "error")
            });
        }// eslint-disable-next-line
     }, [process,user]);


    const deleteFilter = () => {

        FilterService.deleteFilter(selectedFilter.value).then(
            response => {
                setSelectedFilter("");
                getAllFilters();
                handleDialogClose();
            })
            .catch(err => {
                let error = err?.response?.data?.errorMessage ? err.response.data.errorMessage :err.response.data.message

                updateMessage('Unable to delete filter' + error, "error");
            });

    };

    //Fetching all selected criteria as a mapto save filter
    const retrieveCriteriaAttributeValuesToSave = () => {
        const criteria = {};
        for(const [, value] of Object.entries(keyOptions)) {


            if( value.selectedAttributes!== undefined && value.selectedAttributes.length!==0){
                criteria[value.value] = [];
                value.selectedAttributes.forEach(attributeOptions => {
                    criteria[value.value].push(attributeOptions.value);
                });
            }

        }
        return criteria;
    };

    // const setKeyOptionsOnSetSelectedFilter = (filter) => {
    //     let keyValuePair = filter.value.filterCriteria;
    //
    //     let newCriteria = {};
    //
    //     Object.keys(keyValuePair).forEach(item=>{
    //         newCriteria[item] = [];
    //             keyValuePair[item].forEach(value => {
    //                 newCriteria[item].push({
    //                     label: value,
    //                     value: value
    //                 });
    //             })
    //     });
    //
    //     setKeyOptions([]);
    //     setKeySelectionCriteria({});
    //     for (const attr of Object.values(attributes)) {
    //         setKeyOptions(prevState => [...prevState, {label: attr.attributeName,
    //             value: attr.attributeId,
    //             attributeValues:attr.attributeValues.map(elem => ({label: elem, value: elem})).sort((a, b) =>
    //                 {
    //                     let l =  a.value.toLowerCase(), r = b.value.toLowerCase();
    //
    //                     if (l < r) { return -1; }
    //                     if (l > r) { return 1; }
    //                     return 0;
    //                 }
    //             ),
    //             selectedAttributes:newCriteria[attr.attributeId],
    //             selectedValues:newCriteria[attr.attributeId],
    //             coreAttribute:attr.coreAttribute
    //         }]);
    //
    //         setKeySelectionCriteria(prevState => {
    //             if(attr.attributeId in newCriteria) {
    //                 prevState[attr.attributeId] = newCriteria[attr.attributeId].map(elem => elem.value);
    //             } else {
    //                 prevState[attr.attributeId] = attr.attributeValues;
    //                 if(!attr.coreAttribute) {
    //                     prevState[attr.attributeId].push('');
    //                 }
    //             }
    //             return prevState;
    //         });
    //     }
    // };

    const saveFilter = () => {
        if(!validateNameCharacters(filterName)) {//Validate the composition of draft name characters
            if(filterName.trim().length === 0) {
                setFilterNameError("Filter name cannot be empty");
                updateMessage("Filter name cannot be empty", "error");
            }
            else {
                setFilterNameError("Filter name should only contain alphanumerics or spaces");
                updateMessage("Filter name should only contain alphanumerics or spaces", "error");
            }
            return;
        }
        if(filterOptions.length === 10)
        {
            setFilterNameError("You cannot have more than 10 filters for a process.");
            updateMessage("You cannot have more than 10 filters for a process.", "error");
            return;
        }
        else {
            setFilterNameError("");
        }
        let filterCriteria = retrieveCriteriaAttributeValuesToSave();
        const requestObj = {
            body: {
                processId:process.processId,
                filterName: filterName.trim(),
                filterCriteria:filterCriteria,
                active:true
            }
        };
        FilterService.saveFilter(requestObj).then(
            response => {
                setSelectedFilter("");
                getAllFilters();
                handleDialogClose();
            })
            .catch(err => {

                let error = err?.response?.data?.errorMessage ? err.response.data.errorMessage :err.response.data.message

                setFilterNameError(error);
                updateMessage( error, "error");
            });
    };

    const getAllFilters = () => {
        FilterService.getAllFilters(process.processId).then(
            response => {
                 setFilterOptions(
                    Object.values(response.filters).map(filter => ({label: filter.filterName,
                        value: filter
                    })));
            }).catch(err => {

                updateMessage('Unable to fetch filters for the process' + err.response.data.message, "error");
            });
    };

    /**
     * Function to pop up the confirmation dialog for deleting a filter
     */
    // const confirmDeleteFilter = () => {
    //     confirmationDialogContent.current = CONFIRM_ACTION_CONSTANTS.DELETE_FILTER;
    //     setOpenConfirmationDialog(true);
    // };

    /**
     * Function to pop up the confirmation dialog for deleting  keys
     */
    const confirmDeleteKeys = () => {
        if(!verifyUpdateReasonSelected(updateReasonRef.current, updateMessage)) {
            return;
        }
        confirmationDialogContent.current = CONFIRM_ACTION_CONSTANTS.BULK_DELETE_KEYS;
        setOpenConfirmationDialog(true);
    };

    /**
     * Function to pop up the confirmation dialog for bulk edit keys
     */
    const confirmBulkUpdateKeys = () => {
        confirmationDialogContent.current = CONFIRM_ACTION_CONSTANTS.BULK_UPDATE_KEYS;
        setOpenConfirmationDialog(true);
    };

    /**
     * Handler to update the change in filter name input
     */
    const handleFilterNameChange = (event) => {
        setFilterName(event.detail.value.toUpperCase());
    };

    const updateUserFilter = () => {
        setGenerateButton(false);
        setGenerateRMLoading(true);
        if(DUMMY_VALUES.has(userFilter))
        {
            setAliasError("");
            setUserLoaded(true);
            setGenerateTable(false);
            setGenerateButton(false);
            getUserSpecificKeys(userFilter);
        } else if (userFilter !== undefined && userFilter !== null && userFilter !== "") {
            UserService.getActiveInactiveUser(userFilter.toLowerCase()).then(
                response => {
                    setAliasError("");
                    setUserLoaded(true);
                    setUserFilter(response.userAlias);
                    setGenerateTable(false);
                    getUserSpecificKeys(response.userAlias);
                })
                .catch(err => {
                    setGenerateButton(true);
                    setGenerateRMLoading(false);
                    setUserLoaded(false);
                    setGenerateTable(false);
                    setAliasError(`Unable to find alias ${userFilter} in system.`);
                });
        } else {
            setAliasError("");
            setUserLoaded(true);
            setGenerateTable(false);

            let callSearchApi = true;
            for (const attrId of MANDATORY_ATTRIBUTE_IDS) {
                if ((attrId in keySelectionCriteria && keySelectionCriteria[attrId].length !== 1) || !(attrId in keySelectionCriteria)){
                    setMandatoryAttributesMissing(true);
                    setGenerateButton(false);
                    setGenerateRMLoading(false);
                    setGenerateTable(false);
                    callSearchApi = false;
                    break;
                }
            }

            let totalCombinationCount = 1;
            Object.entries(keySelectionCriteria).forEach(([k,v]) => {
                totalCombinationCount = totalCombinationCount * v.length;
            });

            if (totalCombinationCount > 150000) {
                setCriteriaOverflowError(true);
                callSearchApi = false;
            }

            if (callSearchApi) {
                getUserSpecificKeys(null);
            }
        }
    };

    const resetMandatoryAttributesSelectionError = () => {
        setCriteriaOverflowError(false);
        setMandatoryAttributesMissing(false);
        setGenerateButton(true);
    };

    // function that pulls all the keys when this page is loaded
    const getUserSpecificKeys = (alias)=> {
        setLoading(true);

        // get keys under user alias
        if (alias !== undefined && alias !== null && alias !== "") {
            let aliasValue;
            if(DUMMY_VALUES.has(alias))
                aliasValue = alias;
            else aliasValue =alias.toLowerCase();
            KeyService.getMatchingKeysForUserAndProcess(aliasValue, process.processId, userType.value, userStatus.value).then(
                response => {
                    setKeys(response.keys);
                    setLoading(false);
                    setGenerateTable(true);
                    filterKeys(response.keys)
                    setGenerateButton(true);
                    setGenerateRMLoading(false);
                }
            ).catch(err => {
                setGenerateButton(true);
                setGenerateRMLoading(false);
                setUserLoaded(false);
                setGenerateTable(false);
                err?.response?.data?.errorMessage ?
                    updateMessage('Unable to fetch keys for user' + alias + err.response.data.errorMessage, "error")
                    : updateMessage("Unable to fetch keys for user." + alias, "error")
            });
        // get keys without user alias
        } else {
            KeyService.getMatchingKeysForCriteriaAndProcess(keySelectionCriteria, process.processId).then(
                response => {
                    setKeys(response.keys);
                    setLoading(false);
                    setRelevantKeys(flattenKeys(response.keys, attributes));
                    setGenerateTable(true);
                    setGenerateButton(true);
                    setGenerateRMLoading(false);
                }
            ).catch(err => {
                setGenerateButton(true);
                setGenerateRMLoading(false);
                setUserLoaded(false);
                setGenerateTable(false);
                setLoading(false);
                err?.response?.data?.errorMessage ?
                    updateMessage('Unable to fetch keys for process' + process.processId + err.response.data.errorMessage, "error")
                    : updateMessage("Unable to fetch keys for process." + process.processId, "error")
            });
        }
    };

    //Filter Keys
    const filterKeys = (response= keys) => {
        let result = response;
        setLoading(true);

       //Filtering Keys according the key filter criterias provided
        let filteredOptions =
            keyOptions.filter(option => option.selectedAttributes!== undefined && option.selectedAttributes.length !== 0);
        filteredOptions.forEach(opt => {
                  result = result.filter(key =>
                      ((key.keyCriteria)[opt.value] !== undefined) &&
                      (Object.values(opt.selectedAttributes).map(v => v.label)).includes(key.keyCriteria[opt.value])
                  )
        });

        setRelevantKeys(flattenKeys(result, attributes));
        setGenerateTable(true);
        setLoading(false);
    };

    /**
     * Function to reset selected bulkedit option after a successful operation
     */
    const resetSelectedValues =() =>{

        setSelectedBulkEditLabels(initialEdit);
        setSelectedBulkEditValues([]);
        setBulkEditUserNames([]);
        setSelectedActions([]);
        setBulkEditStarted(false);
    };

    const reloadPageOnSuccessfulBulkEdit = ()=> {
        //Reload keys for successful edit

        // user alias not provided
        if(userFilter === null || userFilter === ""){
            setAliasError("");
            setUserLoaded(true);
            setUserFilter(userFilter);
            setGenerateTable(false);
            setGenerateRMLoading(true);
            // get newly edited keys
            getUserSpecificKeys(userFilter);
        }
        // user alias provided
        else {
            UserService.getActiveInactiveUser(userFilter.toLowerCase()).then(
                response => {
                    setAliasError("");
                    setUserLoaded(true);
                    setUserFilter(response.userAlias);
                    setGenerateTable(false);
                    setGenerateRMLoading(true);
                    // get newly edited keys
                    getUserSpecificKeys(response.userAlias);
                })
                .catch(err => {
                    setGenerateButton(false);
                    setUserLoaded(false);
                    setGenerateTable(false);
                    setAliasError(`Unable to find alias ${userFilter} in system.`);
                    setBulkEditStarted(false);
                });
        }

    };

    const bulkDeleteKeys = () => {
        let requestObj = {
            processId: process.processId,
            loggedInAlias: user.userAlias,
            updateReason: updateReasonRef.current,
            simTicketId: simTicketIdRef.current,
            notes: notesRef.current,
            keys: relevantKeys.map(key => key.keyId)
        };

        KeyService.bulkDeleteKeys(requestObj).then(
            response => {
                if(response?.deleteSuccessfulKeys?.keys?.length > 0)
                {
                    updateMessage("Successfully deleted keys!","success")
                    resetSelectedValues();
                    reloadPageOnSuccessfulBulkEdit();
                    handleDialogClose();
                }
               else{
                    handleDialogClose();
                    updateMessage("Unable to Bulk Delete!", "error");
                }
            }
        )
            .catch(err => {
            // on failure show error message
            err?.response?.data?.errorMessage ?
                updateMessage("Unable to Bulk Delete ! " + err.response.data.errorMessage, "error")
                : updateMessage("Unable to Bulk Delete!", "error")
                setBulkEditStarted(false);
                handleDialogClose();
        })
        .finally(() => {
            //Set the additional info attributes values to empty
            updateReasonRef.current = "";
            simTicketIdRef.current = "";
            notesRef.current = "";
        });
    };

    const bulkEditKeys = () => {
        let requestObj = {
            processId: process.processId,
            loggedInAlias: user.userAlias,
            keys: relevantKeys.map(key => key.keyId),
            receiveNotification:receiveNotification,
            updateReason: updateReasonRef.current,
            simTicketId: simTicketIdRef.current,
            notes: notesRef.current
        };
       let criteriaToUpdate = {};
        selectedBulkEditLabels.forEach((selectedLabel,index) => {

            if(selectedLabel.label === "Assignee")
            {
                requestObj['assignee'] = selectedBulkEditValues[index].toLowerCase();
            }
            else if(selectedLabel.label === "Delegate 1" && selectedActions[index].label === EDIT_TEXT)
            {
                requestObj['delegate1'] = {
                    alias: selectedBulkEditValues[index].toLowerCase(),
                    rank: 1,
                    confirmationStatus: "CONFIRMED"
                };
            }
            else if(selectedLabel.label === "Delegate 1" && selectedActions[index].label === REMOVE_TEXT)
            {
                requestObj['delegate1'] = {
                    alias: DELETE_DELEGATE_TEXT,
                    name:"",
                    rank: 1,
                    confirmationStatus: "CONFIRMED"};
            }
            else if(selectedLabel.label === "Delegate 2" && selectedActions[index].label === EDIT_TEXT)
            {
                requestObj['delegate2'] = {
                    alias: selectedBulkEditValues[index].toLowerCase(),
                    rank: 2,
                    confirmationStatus: "CONFIRMED"
                };
            }
            else if(selectedLabel.label === "Delegate 2" && selectedActions[index].label === REMOVE_TEXT)
            {
                requestObj['delegate2'] = {
                    alias: DELETE_DELEGATE_TEXT,
                    name:"",
                    rank: 2,
                    confirmationStatus: "CONFIRMED"};

            }
            else{
                criteriaToUpdate[attributeIDNameMap[selectedLabel.label]] = selectedBulkEditValues[index];
            }

        });

        //Only passing criteria when any criteria is selected to update. Passing empty criteria causes failure of permission service
        if(Object.keys(criteriaToUpdate).length !== 0){
            requestObj['criteriaToUpdate'] = criteriaToUpdate;
        }


        handleDialogClose();
        KeyService.bulkUpdateKeys(requestObj).then(
            response => {
                console.log(response);
                if(response?.updateSuccessfulKeys?.keys?.length > 0)
                {
                    updateMessage("Successfully edited keys!","success")
                    resetSelectedValues();
                    reloadPageOnSuccessfulBulkEdit()
                    setBulkEditStarted(false);
                }
                else{
                    updateMessage("Unable to Bulk Edit!", "error")
                    setBulkEditStarted(false);
                }
            }
        )
            .catch(err => {
                // on failure show error message
                err?.response?.data?.errorMessage ?
                    updateMessage("Unable to Bulk Edit ! " + err.response.data.errorMessage, "error")
                    : updateMessage("Unable to Bulk Edit!", "error")
                setBulkEditStarted(false);
            })
            .finally(() => {
                //Set the additional info attributes values to empty
                updateReasonRef.current = "";
                simTicketIdRef.current = "";
                notesRef.current = "";
            });
    };


    const clearAllFilters = () =>{
        setMandatoryAttributesMissing(false);
        setSelectedFilter("");
        setKeyOptions(
            Object.values(attributes).map(attribute => ({label: attribute.attributeName,
                value: attribute.attributeId,
                attributeValues:attribute.attributeValues.map(elem => ({label: elem, value: elem})).sort((a, b) =>
                {
                    let l =  a.value.toLowerCase(), r = b.value.toLowerCase();
                    if (l < r) { return -1; }
                    if (l > r) { return 1; }
                    return 0;
                }
                ),
                selectedAttributes:[],
                selectedValues:[],
                coreAttribute:attribute.coreAttribute
            })));
    };





    /**
     * Function to retrieve the current confirmation dialog content based on the action selected by the user
     */
    const generateConfirmationDialogContent = () => {
        switch(confirmationDialogContent.current) {
            case CONFIRM_ACTION_CONSTANTS.SAVE_FILTER: return generateSaveFilterDialogContent();
            case CONFIRM_ACTION_CONSTANTS.DELETE_FILTER: return generateDeleteFilterContent();
            case CONFIRM_ACTION_CONSTANTS.BULK_UPDATE_KEYS: return generateBulkEditDialogContent();
            case CONFIRM_ACTION_CONSTANTS.BULK_DELETE_KEYS: return generateDeleteKeysContent();

            default: return null;
        }
    };

    /**
     * Function to generate the content for the confirmation dialog in the case of save filter
     * action selected by the user
     */
    const generateFilterNameContent = () => {
        return (
            <Row  className="p-1 mb-3 justify-content-md-center row-control draft-name-wrapper">
                <Col lg={2}
                     className={`d-flex draft-name-label-wrapper`}>
                    <FormField className="pt-2 px-2"
                               label="Filter Name"/>
                </Col>
                <Col lg={2} className={`justify-content-center draft-name-input-wrapper`}>
                    <Input id="criteria-filter-name-input"
                           type='text'
                           className = "pt-2 px-4 justify-content-md-left"
                           value = {filterName}
                           onChange = {handleFilterNameChange}
                           autoComplete = {false}/>
                </Col>
                <Col lg={1} className={`justify-content-center pt-1 draft-name-error-wrapper`}>
                    <FormField id= "username-filter-error-label "
                               className="pt-2 px-2 fw-bold error-label"
                               errorText={filterNameError}/>
                </Col>
            </Row>
        );
    };

    /**
     * Function to generate the content for the confirmation dialog in the case of delete filter
     * action selected by the user
     */
    const generateDeleteFilterContent = () => {
        return (
            <DialogContainer>
                <Box className="delete-draft-title-wrapper">
                    <h4>Do you want to delete the saved filter?</h4>
                </Box>
                <Grid className="py-5 justify-content-md-center"  gridDefinition={[{ colspan: 5 }, { colspan: 6 }]}>
                    <div  className="d-flex justify-content-center">
                        <Button id="bulk-edit-confirm-delete-filter-button"
                                onClick={()=> {
                                    deleteFilter();
                                    setSelectedFilter("");
                                }}
                                className="p-2 save-draft-button keysButton"
                        >Confirm</Button>
                    </div>
                    <div  className="d-flex justify-content-center">
                        <Button id="bulk-edit-cancel-delete-filter-button"
                                onClick={()=> handleDialogClose()}
                                variant="normal"
                                className="p-2 editButton keysButton cancel-button-wrapper"
                        >Cancel</Button>
                    </div>
                </Grid>
            </DialogContainer>
        );
    };

    /**
     * Function to generate the content for the confirmation dialog in the case of delete filter
     * action selected by the user
     */
    const generateDeleteKeysContent = () => {
        return (
                <DialogContainer
                    header={<Header variant="h2">Delete Keys?</Header>}
                >
                    <Box className="delete-draft-title-wrapper">
                        <h4>{"The action will delete "+ relevantKeys.length +" keys. Do you want to delete the keys? This action is irreversible."}</h4>
                    </Box>
                    <Grid className="py-5 justify-content-md-center"  gridDefinition={[{ colspan: 5 }, { colspan: 6 }]}>
                        <div  className="d-flex justify-content-center">
                            <Button id="bulk-edit-confirm-delete-button"
                                    onClick={handleClickBulkDeleteConfirmed}
                                    className="p-2 save-draft-button keysButton"
                            >Confirm</Button>
                        </div>
                        <div  className="d-flex justify-content-center">
                            <Button id="bulk-edit-confirm-cancel-button"
                                    onClick={()=> handleDialogClose()}
                                    variant="normal"
                                    className="p-2 editButton keysButton cancel-button-wrapper"
                            >Cancel</Button>
                        </div>
                    </Grid>
                </DialogContainer>
        );
    };

    /**
     * Function to generate the filter name content for the confirmation dialog.
     */
    const generateSaveFilterDialogContent = () => {
        return (
            <DialogContainer
                header={<Header variant="h2">Save Filter?</Header>}
            >
                {generateFilterNameContent()}
                <Grid className="py-5 justify-content-md-center"  gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <div className="d-flex justify-content-center">
                        <Button id="bulk-edit-save-filter-button"
                                onClick={()=> saveFilter()}
                                className="p-2 save-draft-button keysButton"
                        >Save</Button>
                    </div>
                    <div  className="d-flex justify-content-center">
                        <Button id="bulk-edit-cancel-filter-button"
                                onClick={()=> handleDialogClose()}
                                variant="normal"
                                className="p-2 editButton keysButton cancel-button-wrapper"
                        >Cancel</Button>
                    </div>
                </Grid>
            </DialogContainer>
        );
    };

    /**
     * Function to close the confirmation dialog
     */
    const handleDialogClose = () => {
        setOpenConfirmationDialog(false);
    };

    /**
     * Function to check if the update op was a delete delegate operation .
     * This is a helper method for display of confirmation dialog for bulk update of keys
     */
    const isDeleteDelegate = (val,index)  =>{
        if(val ==="Delegate 1" || val === "Delegate 2")
        {
            if(selectedActions[index] !== undefined &&  selectedActions[index].label=== REMOVE_TEXT)
            {
                return true;
            }
        }
        return false
    }

    /**
     * Function to check if the update operation was a update delegate or update assignee operation .
     * This is a helper method for display of confirmation dialog for bulk update of keys
     */
    const isUpdateDelegateorAssignee = (val,index)  =>{
        if(val=== "Assignee")return true;
        if(val ==="Delegate 1" || val === "Delegate 2")
        {
            if(selectedActions[index]!== undefined && selectedActions[index].label === EDIT_TEXT)
            {
                return true;
            }
        }
        return false
    }

    /**
     * Helper function for validateBulkEditSelectedValues to mark that selected value has validation error
     * @param index
     */
    const bulkEditSelectedValueHasError = (index) => {
        let valueErrors = bulkEditValidateErrors;
        valueErrors[index] = true
        setBulkEditValidateErrors(valueErrors);
        setBulkEditValidateError(true);
    }

    const asyncValidateBackgroundActivityAndAttributesNoCatch = async () => {
        let attributeList = Object.values(attributes);
        attributeList.forEach(attr => {attr.alias = user.userAlias});
        let result = await Amplify.API.post("ApproverMatrixAPI", "/background-activity", {
            body: {
                processId: process.processId,
                activityType: ACTIVITY_TYPE_ATTR_VALUES_UPDATE,
                attributeValidation: true,
                attributes: attributeList
            }
        });
        console.log(result);
        return !(result.existUnfinishedActivity || !result.attributeValuesMatched);
    }

    /**
     * Function to validate whether the alias selected for assignee and delegate 1 and delegate 2
     * This is a helper
     */
    const validateBulkEditSelectedValues = () => {
        let errors = [];
        let names = [];
        selectedBulkEditLabels.forEach((selectedLabel,index) => {
            errors.push(false);
            names.push("");
        });
        setBulkEditValidateErrors(errors);
        setBulkEditUserNames(names);

        let userPromises = [];
        selectedBulkEditLabels.forEach((selectedLabel,index) => {
            // employee name to display in confirmation dialog
            let userNames = bulkEditUserNames;
            // new value to change to
            let newValue = selectedBulkEditValues[index];

            // no value is selected, display error in confirmation popup
            if ((newValue === null || newValue === undefined || Object.keys(newValue).length === 0) &&
                !isDeleteDelegate(selectedLabel.label, index)) {
                userPromises.push(new Promise((resolve) => {
                    bulkEditSelectedValueHasError(index);
                    return resolve("No value is selected");
                    })
                );
            }
            // action is delete delegate
            else if (isDeleteDelegate(selectedLabel.label, index)) {
                userPromises.push(new Promise((resolve) => {
                    UserService.validateUser(DELETE_DELEGATE_TEXT).then(response => {
                        if (!response.validUser) {
                            bulkEditSelectedValueHasError(index);
                            return resolve("Alias to be deleted is not a valid user");
                        } else {
                            return resolve("Alias to be deleted is a valid user");
                        }
                    }).catch(err => {
                        bulkEditSelectedValueHasError(index);
                        return resolve("Error occurred while validating alias to be deleted");
                    })
                }));
            }
            //  action is editing delegate or assignee
            else if (isUpdateDelegateorAssignee(selectedLabel.label, index)) {
                let val = newValue !== null ? newValue.toLowerCase() : "";
                userPromises.push(new Promise((resolve, reject) => {
                    UserService.validateUser(val).then((response) => {
                        if (!response.validUser) {
                            bulkEditSelectedValueHasError(index);
                            return resolve("Alias to update is not valid user");
                        } else {
                            UserService.getUser(newValue.toLowerCase()).then(response => {
                                userNames[index] = response.userName;
                                setBulkEditUserNames(userNames);
                                return resolve("Alias to be updated is valid user");
                            })
                        }
                    }).catch(err => {
                        bulkEditSelectedValueHasError(index);
                        return resolve("Error occurred while validating alias to be updated");
                    })
                }));
            }
        });
        return Promise.all(userPromises);
    }

    const makeValuestoDisplayInConfirmation = async function() {
        if(!verifyUpdateReasonSelected(updateReasonRef.current, updateMessage)) {
            return;
        }
        setActiveUpdateButton(false);
        await validateBulkEditSelectedValues();
        confirmBulkUpdateKeys();
        setActiveUpdateButton(true);
    }

    const getValuetoDisplay = (label,index) => {
       return  !isDeleteDelegate(label,index) ?
              isUpdateDelegateorAssignee(label,index) && !bulkEditValidateErrors[index]? bulkEditUserNames[index] :
                  selectedBulkEditValues[index] : REMOVE_TEXT;
    }

    const handleClickBulkEditConfirmed = async ()=>{
        let passed = false;
        try{
            passed = await asyncValidateBackgroundActivityAndAttributesNoCatch();
            if (!passed){
                setBulkEditStarted(false);
                updateMessage("Unable to bulk edit keys because there are other admins editing this process! Please come back later and refresh page", "error");
            }
        }catch(err){
            setBulkEditStarted(false);
            updateMessage("Unable to bulk edit keys because we cannot validate background activity and attribtues!", "error");
            console.log(err);
        }
        if (passed){
            bulkEditKeys();
            setBulkEditStarted(true);
        }
    }

    const handleClickBulkDeleteConfirmed = async ()=>{
        let passed = false;
        try {
            passed = await asyncValidateBackgroundActivityAndAttributesNoCatch();
            if (!passed){
                updateMessage("Unable to bulk delete keys because there are other admins editing this process! Please come back later and refresh page", "error");
            }
        }catch(err){
            console.log(err);
            updateMessage("Unable to bulk delete keys because we cannot validate background activity and attribtues!", "error");
        }
        if (passed){
            bulkDeleteKeys();
        }
    }

    const handleClickImportFromCSV = async ()=>{
        let passed = false;
        try {
            passed = await asyncValidateBackgroundActivityAndAttributesNoCatch();
            if (!passed){
                updateMessage("Unable to import from CSV because there are other admins editing this process! Please come back later and refresh page", "error");
            }
        }catch(err){
            console.log(err);
            updateMessage("Unable to import from CSV because we cannot validate background activity and attribtues!", "error");
        }
        if (passed){
            history.push("/import-key-csv");
        }
    }

    /**
     * Function to generate the bulk edit  keys content for the confirmation dialog.
     */
    const generateBulkEditDialogContent = () => {
        return (
            <DialogContainer header={<Header variant="h2">Update Keys?</Header>}>
                <Box variant="p" className="py-5 px-3 justify-content-md-center">

                    <FormField id={"bulk-edit-confirmirmation-dialog-attribute-header"}
                               label={"The action will update " + relevantKeys.length + " keys with the below values. Do you want to proceed?"}/>

                </Box>
                <Grid className="py-3 px-3  justify-content-md-center"
                    gridDefinition={[{ colspan: 6 }, { colspan: 6 },{ colspan: 6 }]}>
                    <div> <FormField id={'bulk-edit-confirmirmation-dialog-attribute-header'}
                                     label="Attribute"/></div>
                    <div><FormField id={"bulk-edit-confirmirmation-dialog-attribute-value-header"}
                                    label="Value"/></div>
                    <div></div>
                </Grid>


                {
                    selectedBulkEditLabels.map((selectedLabel,index) => {
                        return (
                            <Grid className="py-3 px-3  justify-content-md-center"
                                   gridDefinition={[{ colspan: 6 }, { colspan: 6 },{ colspan: 6 }]}
                                  >

                                <div lg={6} className="d-flex justify-content-left">
                                    <FormField id={'bulk-edit-confirmirmation-dialog-attribute' + index}
                                               label={selectedLabel.label}/>
                                </div>
                                <div lg={6} className="d-flex justify-content-left">
                                    <FormField id={"bulk-edit-confirmirmation-dialog-attribute-value" + index}
                                               label=
                                                   {
                                                       getValuetoDisplay(selectedLabel.label,index)
                                                   }/>
                                </div>
                                <div lg={6} className="d-flex justify-content-left">
                                {
                                    bulkEditValidateErrors[index]?
                                            <FormField id={"bulk-edit-confirmirmation-dialog-attribute-value" + index}
                                                       errorText="Selected Alias is an invalid user"/>
                                      :
                                        undefined
                                }
                                </div>
                            </Grid>
                        )
                    })
                }
                <Grid className="py-5 justify-content-md-center">
                    <div lg={6} className="d-flex justify-content-center"  gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
                        <Button id="bulk-edit-submit-update-button"
                                disabled={bulkEditValidateError}
                                onClick={handleClickBulkEditConfirmed}
                                className="p-2 save-draft-button keysButton"
                        >Submit</Button>
                    </div>
                    <div lg={6} className="d-flex justify-content-center">
                        <Button id="bulk-edit-cancel-update-button"
                                onClick={()=>
                                    {
                                        handleDialogClose();
                                        setBulkEditValidateError(false);
                                    }
                                }
                                variant="normal"
                                className="p-2 editButton keysButton cancel-button-wrapper"
                        >Cancel</Button>
                    </div>
                </Grid>
            </DialogContainer>
        );
    };

    const onAddNewEditAttribute = useCallback(() => {
        setSelectedBulkEditLabels(selectedBulkEditLabels => [...selectedBulkEditLabels, { }]);
        setSelectedBulkEditValues(selectedBulkEditValues => [...selectedBulkEditValues, { }]);
        setSelectedActions(selectedActions => [...selectedActions, { }]);
        // eslint-disable-next-line
    }, []);

    return (
        <Container className="pt-4 justify-content-lg-center">

            <React.Fragment key="username-search-fragment">
                    <Row id="page-header-row"className="pb-4 justify-content-md-left ">
                        <Col lg={4} className="d-flex justify-content-left fw-bold px-lg-5">
                            <h1 id="page-header">Manage Responsibility Matrix</h1>
                        </Col>
                    </Row>

                    <Row id="username-filter-input-row" className="py-4 justify-content-md-left px-lg-2">
                        <Col lg={1} className="d-flex justify-content-end ">
                            <FormField className="pt-3 px-2 fw-bold" label="User Alias: " />
                        </Col>
                        <Col  lg={2} className="d-flex justify-content-left">
                            <Input id= "username-filter-input-control"
                                   className="mt-2 px-2"
                                   type='text'
                                   onChange={({detail}) => {
                                       setUserLoaded(false);
                                       setUserFilter(detail.value);
                                   }}
                                   value={userFilter}/>
                        </Col >
                        <Col  lg={1} className="d-flex justify-content-end">
                            <FormField className="pt-3  fw-bold" label="User Type: " />
                        </Col>
                        <Col lg={1} className="justify-content-left">
                            <Select id="user-type-selection-control"
                                    placeholder="Not Specified"
                                    className="pt-2 pb-2"
                                     selectedOption={userType}
                                     options={userTypeOptions}
                                     onChange={(event) => {
                                         setUserLoaded(false);
                                         setUserType(event.detail.selectedOption);

                                     }}
                                     autoComplete={false}/>

                        </Col>
                        <Col  lg={1} className="d-flex justify-content-end">
                            <FormField className="pt-3  fw-bold" label="Active: " />
                        </Col>
                        <Col lg={1} className="d-flex justify-content-left py-2">
                            <Select id="user-type-selection-control"
                                    placeholder="Not Specified"
                                    className="pt-2 pb-2"
                                    selectedOption={userStatus}
                                    options={userStatusOptions}
                                    onChange={(event) => {
                                        setUserStatus(event.detail.selectedOption);
                                    }}
                                    autoComplete={false}/>
                        </Col>

                    </Row>
                    <Row id= "username-filter-error-row" className="justify-content-md-left ">
                        <Col lg={3} className=" d-flex px-2 mx-2 d-flex justify-content-center ">
                            <FormField id= "username-filter-error-label"
                                       className="d-flex mx-4"
                                       errorText={aliasError} />
                        </Col>
                    </Row>
                </React.Fragment>



            <>
                <React.Fragment key="criteria-filter-provider-fragment">

                    {/*<Row id= "criteria-filter-section-header-row" className="py-4 justify-content-md-left">*/}
                    {/*        <Col id="bulk-edit-select-action-col" lg={2} className={"d-flex justify-content-left px-lg-5"}>*/}
                    {/*            <FormField id="bulk-edit-select-action-label" className="pt-3  fw-bold"*/}
                    {/*                       label="Select Saved Filter"/>*/}
                    {/*        </Col>*/}

                    {/*        <Col lg={3} className={" justify-content-end"}>*/}
                    {/*            <Select id="bulk-edit-select-action-dropdown"*/}
                    {/*                    placeholder="Not Specified"*/}
                    {/*                    className="pt-1 pb-2"*/}
                    {/*                    selectedOption={selectedFilter}*/}
                    {/*                    options={filterOptions}*/}
                    {/*                    onChange={(event) =>*/}
                    {/*                    {*/}
                    {/*                        setSelectedFilter(event.detail.selectedOption);*/}
                    {/*                        setKeyOptionsOnSetSelectedFilter(event.detail.selectedOption);*/}
                    {/*                        setEditFilter(true);*/}
                    {/*                    }}*/}
                    {/*                    autoComplete={false}/>*/}
                    {/*        </Col>*/}
                    {/*        <Col lg={1} className="d-flex justify-content-left">*/}
                    {/*            <Button*/}
                    {/*                size="lg"*/}
                    {/*                id="delete-filter-table-action-button"*/}
                    {/*                className="d-flex searchButton mt-2 mb-4 reset-all-button-wrapper text-nowrap"*/}
                    {/*                onClick={() => {*/}
                    {/*                    confirmDeleteFilter();*/}
                    {/*                }}*/}
                    {/*            >Delete Saved Filter</Button>*/}
                    {/*        </Col>*/}
                    {/*</Row>*/}

                    <Row id= "criteria-filter-section-header-row" className="py-4 justify-content-md-left">
                        <Col lg={4} className="d-flex justify-content-lg-end px-3 fw-bold">
                            <h2 id= "criteria-filter-section-header">Filter Criteria</h2>
                        </Col>

                    </Row>

                    {
                        keyOptions.map((attribute,index) => {
                            return (
                                <MultiAttributeFilter id="criteria-filter-div"
                                                      key={index}
                                                      attribute={attribute}
                                                      index={index}
                                                      keyOptions={keyOptions}
                                                      setSelectedFilter={setSelectedFilter}
                                                      setKeyOptions={setKeyOptions}
                                                      setSelectedAttributeValues={setKeySelectionCriteria}
                                                      resetMandatoryAttributesSelectionError={resetMandatoryAttributesSelectionError}
                                />
                            )
                        })
                    }

                    <Row id= "criteria-filter-selection-missing-row" className="py-4 justify-content-md-left">
                        <Col lg={10} className="d-flex justify-content-left px-3 fw-bold">
                            <Alert
                                onDismiss={() => setMandatoryAttributesMissing(false)}
                                visible={mandatoryAttributesMissing}
                                dismissAriaLabel="Close alert"
                                dismissible
                                type="warning"
                            >
                                Please specify exactly one value for each of these - {[...MANDATORY_ATTRIBUTES].join(', ')}.
                            </Alert>
                        </Col>
                    </Row>

                    <Row id= "criteria-filter-selection-missing-row" className="py-4 justify-content-md-left">
                        <Col lg={10} className="d-flex justify-content-left px-3 fw-bold">
                            <Alert
                                onDismiss={() => setCriteriaOverflowError(false)}
                                visible={criteriaOverflowError}
                                dismissAriaLabel="Close alert"
                                dismissible
                                type="warning"
                            >
                                    Too many criteria combinations possible. Please refine your search.
                            </Alert>
                        </Col>
                    </Row>

                    <Row id="generate-filter-table-action-row" className="justify-content-md-left px-lg-3">
                        <Col lg={2} className="d-flex justify-content-center">
                            <Button id="generate-filter-table-action-button"
                                    className="d-flex searchButton mt-2 mb-4"
                                    size="lg"
                                    disabled={!generateButton}
                                    onClick={() => {
                                        updateUserFilter();
                                        resetSelectedValues();
                                    }
                                    }>Generate Responsibility Matrix</Button>
                        </Col>
                        { (user.devAdmin || process.processAdmins.includes(user.userAlias)) ?
                            <Col lg={2} className="d-flex justify-content-center">
                                <Button
                                    size="lg"
                                    id="bulk-import-keys-button"
                                    className="d-flex searchButton mt-2 mb-4 justify-content-left text-nowrap"
                                    onClick={handleClickImportFromCSV}>
                                        Import Keys from CSV
                                </Button>

                            </Col>: null
                        }
                        <Col lg={1} className="d-flex justify-content-left">
                            <Button
                                size="lg"
                                id="reset-filter-table-action-button"
                                className="d-flex searchButton mt-2 mb-4 reset-all-button-wrapper"
                                onClick={() => {clearAllFilters()}}
                            >Reset All</Button>
                        </Col>
                    </Row>
                    {
                        generateRMLoading ?
                            <Row id="generateRM-spinner-row" className="justify-content-md-center" >
                                <Col lg={4} className="mt-5 d-flex justify-content-center">
                                    <CircularProgress id="generateRM-spinner" className="ms-3 mb-1" size={40}/>
                                    <FormField id="generateRM-spinner-label"className="pt-3 px-2 fw-bold"
                                               label="Fetching keys..."></FormField>
                                </Col>
                            </Row>:undefined
                    }
                </React.Fragment>

                <React.Fragment key="filtered-table-fragment">
                    { generateTable && userLoaded ?
                      <FilteredKeysTable elementId="bulk-edit-"
                                         relevantKeys={relevantKeys}
                                         attributes = {attributes}
                                         enableExport={user.devAdmin || process.processAdmins.includes(user.userAlias)}
                                         loading={loading} />
                        : undefined

                    }
                </React.Fragment>

                <React.Fragment key="bulk-key-edit-fragment">
                    {
                        userLoaded && generateTable && (user.devAdmin || (userFilter !== undefined && userFilter !== null && userFilter !== "" && user.userAlias === userFilter) || process.processAdmins.includes(user.userAlias)) ?
                             <>

                                <Row id="bulk-edit-section-header-row" className="py-4 justify-content-md-left px-lg-1">
                                    <Col lg={6} className="d-flex justify-content-end fw-bold">
                                        <h2 id="bulk-edit-section-header">Update Responsibility Matrix</h2>
                                        <React.Fragment>
                                            <Button id="addAttributeRow" variant="primary"
                                                    size="lg"
                                                    onClick={()=>{onAddNewEditAttribute()}}
                                                    className="mx-4 p-2 add-button process-button">

                                                <Icon id="plusIcon" className="ms-2"
                                                      path={mdiPlusCircleOutline}
                                                      color="white"
                                                      size={1.3} >
                                                </Icon>
                                                <span className="ms-2 px-2" >Add</span>
                                            </Button>
                                        </React.Fragment>
                                    </Col>

                                </Row>

                                 {
                                     selectedBulkEditLabels.map((attribute,index) => {
                                         return (
                                         <RACIMatrixAttributeEdit
                                             bulkEditOptions = {bulkEditOptions}
                                             actionOptions = {actionOptions}
                                             selectedBulkEditLabels = {selectedBulkEditLabels}
                                             setSelectedBulkEditLabels ={setSelectedBulkEditLabels}
                                             selectedBulkEditValues = {selectedBulkEditValues}
                                             setSelectedBulkEditValues ={setSelectedBulkEditValues}
                                             selectedActions = {selectedActions}
                                             setSelectedActions = {setSelectedActions}
                                             setBulkEditValidateErrors = {setBulkEditValidateErrors}
                                             bulkEditValidateErrors ={bulkEditValidateErrors}
                                             index = {index}
                                             attributes = {attributes}>
                                          </RACIMatrixAttributeEdit>
                                         )
                                     })
                                 }

                                 <AdditionalInfo simTicketIdRef={simTicketIdRef} notesRef={notesRef} updateReasons={props.updateReasons}
                                    updateReasonRef={updateReasonRef}>
                                 </AdditionalInfo>

                                 {userLoaded && generateTable && (user.devAdmin || process.processAdmins.includes(user.userAlias)) ?
                                     <Row id="bulk-edit-section-header-row" className="py-4 justify-content-md-left ">
                                         <Col lg={1} className="d-flex justify-content-left py-2">
                                             <input
                                                type="checkbox"
                                                onChange={() => setReceiveNotification(prevCheck => !prevCheck)}
                                                checked={receiveNotification}/>
                                         </Col>
                                         <Col lg={2} className="justify-content-left py-2">
                                             <FormField id="receive-notification-label" className="pt-3"
                                                        label="Issue Notification"/>
                                         </Col>
                                     </Row> : undefined
                                 }

                                
                                 <Row id="bulk-edit-section-header-row" className="py-4 justify-content-md-left px-lg-1">

                                     <Col lg={1} className="d-flex justify-content-left">
                                         <Button id="bulk-edit-submit-button"
                                                 className="d-flex searchButton mt-2 mb-4"
                                                 color="primary" size="lg"
                                                 disabled={!activeUpdateButton}
                                                 onClick={() =>
                                                     {
                                                         makeValuestoDisplayInConfirmation();
                                                     }
                                                 }
                                                 autoComplete={false}> Update
                                         </Button>

                                     </Col>
                                     {
                                         userLoaded && generateTable && (user.devAdmin || process.processAdmins.includes(user.userAlias)) ?
                                         <Col lg={1} className="d-flex justify-content-left">
                                             <Button
                                                 size="lg"
                                                 id="bulk-edit-delete-button"
                                                 className="d-flex searchButton mt-2 mb-4 reset-all-button-wrapper"
                                                 onClick={() => {
                                                     confirmDeleteKeys();
                                                 }}
                                             >Delete</Button>
                                         </Col> : undefined
                                     }

                                 </Row>


                                 <Row id="bulk-edit-section-header-row" className="py-4 justify-content-md-center ">

                                 </Row>
                                 {
                                     bulkEditStarted ?
                                         <Row id="bulk-edit-spinner-row" className="justify-content-md-center" >
                                             <Col lg={4} className="mt-5 d-flex justify-content-center">
                                                 <CircularProgress id="bulk-edit-spinner-" className="ms-3 mb-1" size={40}/>
                                                 <FormField id="bulk-edit-spinner-label" className="pt-3 px-2 fw-bold" label="Update Responsibility Matrix in Progress..."></FormField>
                                             </Col>
                                         </Row>:undefined
                                 }
                                </>
                                :
                                undefined
                    }
                </React.Fragment>
            </>



            <React.Fragment>
                <Row>
                    <Col>
                        <FormField className="pt-3 px-2 fw-bold" label="" />
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <FormField className="pt-3 px-2 fw-bold" label="" />
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <FormField className="pt-3 px-2 fw-bold" label="" />
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <FormField className="pt-3 px-2 fw-bold" label="" />
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <FormField className="pt-3 px-2 fw-bold" label="" />
                    </Col>

                </Row>
            </React.Fragment>

            {openConfirmationDialog ?
                <ConfirmationDialog open={openConfirmationDialog} onClose={handleDialogClose}>
                    {
                        generateConfirmationDialogContent()
                    }
                </ConfirmationDialog>: undefined
            }

        </Container>
    );
}