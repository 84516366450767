/**
* Helper function to generate an older date from the given date by subtracting the specified no.of months
* For instance, if given date is 18th Jan 2023, a 3 month older date would be 18th Oct 2022
*/
export const generateOlderDateInMonths = (date, months) => {
    date.setMonth(date.getMonth() - months);
    return date;
}

/**
 * Helper function to convert the given date into a YYYY/MM/DD format
 */
export const convertDateFormat = (date) => {
    const year = date.toLocaleString("en-CA", { year: "numeric" });
    const month = date.toLocaleString("en-CA", { month: "2-digit" });
    const day = date.toLocaleString("en-CA", { day: "2-digit" });
    // Generate YYYY/MM/DD date string
    return year + "-" + month + "-" + day;
};

/**
 * Verifies whether a value for the update reason has been selected 
 */
export const verifyUpdateReasonSelected = (value, updateMessage) => {
    if(!value) {
        updateMessage("Please select an 'Update Reason'. ","error");
        return false;
    }
    return true;
};

/**
 * This function is implemented as a measure of backward compatibility since there has been a change of changelog db timestamp
 * from seconds to millisecond.
 * Function to convert the given milliseconds / seconds timestamp to milliseconds.
 * Milliseconds will be of length 13 digits till the year 2286.
 * @param {*} timestamp - milliseconds / seconds
 * @returns - milliseconds
 */
export const convertTimestampToMilliseconds = (timestamp) => {
    const numDigits = timestamp.toString().length;
    if(numDigits === 13) return timestamp;
    return timestamp * 1000;
};