import {UserService} from "../../service/UserService";

export const NOT_AVAILABLE_TEXT = "Not Available";
export const NOT_APPLICABLE_TEXT = "Not Applicable";
export const NA_VALUES = new Set([NOT_APPLICABLE_TEXT,NOT_AVAILABLE_TEXT]);
export const PHONE_TOOL_URL_PREFIX = "https://phonetool.amazon.com/users";
export const CHECKPOINT_SUBDIRECTORY = "/checkpoints/";
export const PROCESSVIEW_SUBDIRECTORY = "/process";
export const ACTIVITY_TYPE_ATTR_VALUES_UPDATE = "attrValuesUpdate";
export const ACTIVITY_TYPE_ALL = "allActivities";

export const getOrSaveUser = (userAlias, successGetCallBack, successSaveCallBack, failureCallback) => {
    UserService.getUser(userAlias).then(
        response => {
            successGetCallBack(response);
        })
        .catch(err => {
            //add user to system if not a user
            UserService.saveUser(userAlias).then(
                response=>{
                    successSaveCallBack(response);
                }).catch(err => {
                    failureCallback();
                });
        });
};