import { Redirect } from 'react-router-dom';
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import {Container, Table, Header} from "@amzn/awsui-components-react";
import {useState} from "react";
import _ from "lodash";
import {KeyService} from "../service/KeyService";
import {getOrSaveUser, NA_VALUES} from "./common/Constants";

export default function ImportKeyCsv(props) {

    const updateMessage = props.updateMessage;
    const processId = props.processId;
    const attributes = props.attributes;
    const user = props.user;
    const [ reDirect, setReDirect ] = useState(false);
    const [ uploadErrorReason, setUploadErrorReason ] = useState([]);
    // keep track of user alias encountered in csv
    const userMap = {};
    // keep track of invalid user aliases encountered in csv to prevent unnecessary duplicate calls to getUser()
    const invalidUserMap = new Set();
    const attributeNameMap = {};
    userMap[user.userAlias] = user;
    const STANDARD_FIELDS = new Set(['keyId', 'assignee', 'assigneeName', 'delegate1', 'delegate1Name', 'delegate2', 'delegate2Name']);
    const [ updateError, setUpdateError ] = useState(false);
    const [ createError, setCreateError ] = useState(false);
    const [ totalCreateRows, setTotalCreateRows ] = useState(0);
    const [ totalUpdateRows, setTotalUpdateRows ] = useState(0);
    const [ totalCreatedKeys, setTotalCreatedKeys ] = useState(0);
    const [ totalUpdatedKeys, setTotalUpdatedKeys ] = useState(0);

    const coreAttributesUnused = {};
    Object.values(attributes).filter((attribute) => attribute.coreAttribute)
        .forEach((attribute) => {coreAttributesUnused[attribute.attributeId] = attribute.attributeName});

    const columnDefinitions = [
        {id: "field", header: "Field", sortingField: "field", cell: e => e.field || '-'},
        {id: "keyId", header: "Key ID", sortingField: "keyId", cell: e => e.keyId || '-'},
        {id: "error", header: "Error", sortingField: "error", cell: e => e.error || '-'}
    ];

    const delay = ms => new Promise(res => setTimeout(res, ms));

    async function setupKeysToCreateOrUpdate(rows) {
        const validatedKeys = {"createKeys": [], "updateKeys": []};
        for (const row of rows) {
            const modifiedKey = {};
            let coreAttributesForCurrentKey = _.cloneDeep(coreAttributesUnused);
            modifiedKey['processId'] = processId;
            modifiedKey['alias'] = user.userAlias;
            modifiedKey['hasErrors'] = false;

            if ('keyId' in row && row['keyId'] !== '' && row['keyId'] !== undefined) {
                if (row['keyId'].length < 30 || row['keyId'].length > 40) {
                    modifiedKey['hasErrors'] = true;
                    setUploadErrorReason(prevState => {
                        prevState.push({
                            field: 'Key ID',
                            keyId: row['keyId'],
                            error: 'Key ID: ' + row['keyId'] + ' does not exist.'});
                        return prevState;
                    });
                }
                modifiedKey['keyId'] = row.keyId;
            }
            if (!('assignee' in row)) {
                modifiedKey['hasErrors'] = true;
                setUploadErrorReason(prevState => {
                    prevState.push({
                        field: 'Key Assignee',
                        keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                        error: 'Record is missing key assignee.'});
                    return prevState;
                });
            } else {
                if (row.assignee in userMap) {
                    modifiedKey['keyAssigneeName'] = userMap[row.assignee].userName;
                    modifiedKey['isKeyAssigneeActive'] = userMap[row.assignee].active;
                } else if (invalidUserMap.has(row.assignee)) {
                    modifiedKey['hasErrors'] = true;
                    setUploadErrorReason(prevState => {
                        prevState.push({
                            field: 'Key Assignee',
                            keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                            error: 'Key assignee alias: ' + row.assignee + ' is invalid for record.'
                        });
                        return prevState;
                    });
                } else if (NA_VALUES.has(row.assignee)) {
                    modifiedKey['keyAssigneeName'] = row.assignee;
                    modifiedKey['isKeyAssigneeActive'] = true;
                } else if (!NA_VALUES.has(row.assignee)) {
                    getOrSaveUser(row.assignee, (response) => {
                        userMap[row.assignee] = response;
                        modifiedKey['keyAssigneeName'] = response.userName;
                        modifiedKey['isKeyAssigneeActive'] = response.active;
                    }, (response) => {
                        userMap[row.assignee] = response.user;
                        modifiedKey['keyAssigneeName'] = response.user.userName;
                        modifiedKey['isKeyAssigneeActive'] = response.active;
                    }, () => {
                        invalidUserMap.add(row.assignee);
                        modifiedKey['hasErrors'] = true;
                        setUploadErrorReason(prevState => {
                            prevState.push({
                                field: 'Key Assignee',
                                keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                                error: 'Key assignee alias: ' + row.assignee + ' is invalid for record.'
                            });
                            return prevState;
                        });
                    });
                    await delay(2000);
                } else {
                    modifiedKey['keyAssigneeName'] = row.assigneeName !== undefined ? row.assigneeName : '';
                }
                modifiedKey['keyAssignee'] = row.assignee;
            }
            modifiedKey['keyDelegates'] = [];
            if (row['delegate1'] !== undefined && row['delegate1'] !== '') {
                const del1 = {};
                if ('assignee' in row && row.delegate1 === row.assignee) {
                    modifiedKey['hasErrors'] = true;
                    setUploadErrorReason(prevState => {
                        prevState.push({
                            field: 'Key Delegate 1',
                            keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                            error: 'Key delegate 1 alias: ' + row.delegate1 + ' is same as assignee for record.'
                        });
                        return prevState;
                    });
                } else if (row.delegate1 in userMap) {
                    del1['name'] = userMap[row.delegate1].userName;
                } else if (invalidUserMap.has(row.delegate1)) {
                    modifiedKey['hasErrors'] = true;
                    setUploadErrorReason(prevState => {
                        prevState.push({
                            field: 'Key Delegate 1',
                            keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                            error: 'Key delegate 1 alias: ' + row.delegate1 + ' is invalid for record.'
                        });
                        return prevState;
                    });
                } else if (NA_VALUES.has(row.delegate1)) {
                    modifiedKey['hasErrors'] = true;
                    setUploadErrorReason(prevState => {
                        prevState.push({
                            field: 'Key Delegate 1',
                            keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                            error: 'Key delegate 1 alias: ' + row.delegate1 + ' is invalid for record.'
                        });
                        return prevState;
                    });
                } else if (!NA_VALUES.has(row.delegate1)) {
                    getOrSaveUser(row.delegate1, (response) => {
                        userMap[row.delegate1] = response;
                        del1['name'] = response.userName;
                    }, (response) => {
                        userMap[row.delegate1] = response.user;
                        del1['name'] = response.user.userName;
                    }, () => {
                        invalidUserMap.add(row.delegate1);
                        modifiedKey['hasErrors'] = true;
                        setUploadErrorReason(prevState => {
                            prevState.push({
                                field: 'Key Delegate 1',
                                keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                                error: 'Key delegate 1 alias: ' + row.delegate1 + ' is invalid for record.'
                            });
                            return prevState;
                        });
                    });
                    await delay(5000);
                } else {
                    del1['name'] = row.delegate1Name !== undefined ? row.delegate1Name : '';
                }
                del1['alias'] = row.delegate1;
                del1['rank'] = 1;
                del1['confirmationStatus'] = 'CONFIRMED';
                modifiedKey['keyDelegates'].push(del1);
            } else if (row['delegate1Name'] !== undefined && row['delegate1Name'] !== "") {
                modifiedKey['hasErrors'] = true;
                setUploadErrorReason(prevState => {
                    prevState.push({
                        field: 'Key Delegate 1',
                        keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                        error: 'Key delegate 1 alias: record is missing while key delegate 1 name "' + row['delegate1Name'] + '" is supplied'
                    });
                    return prevState;
                });
            }
            if (row['delegate2'] !== undefined && row['delegate2'] !== '') {
                const del2 = {};
                if ('assignee' in row && row.delegate2 === row.assignee) {
                    modifiedKey['hasErrors'] = true;
                    setUploadErrorReason(prevState => {
                        prevState.push({
                            field: 'Key Delegate 2',
                            keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                            error: 'Key delegate 2 alias: ' + row.delegate2 + ' is same as assignee for record.'
                        });
                        return prevState;
                    });
                } else if ('delegate1' in row && row.delegate2 === row.delegate1) {
                    modifiedKey['hasErrors'] = true;
                    setUploadErrorReason(prevState => {
                        prevState.push({
                            field: 'Key Delegate 2',
                            keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                            error: 'Key delegate 2 alias: ' + row.delegate2 + ' is same as delegate 1 for record.'
                        });
                        return prevState;
                    });
                } else if (row.delegate2 in userMap) {
                    del2['name'] = userMap[row.delegate2].userName;
                } else if (invalidUserMap.has(row.delegate2)) {
                    modifiedKey['hasErrors'] = true;
                    setUploadErrorReason(prevState => {
                        prevState.push({
                            field: 'Key Delegate 2',
                            keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                            error: 'Key delegate 2 alias: ' + row.delegate2 + ' is invalid for record.'
                        });
                        return prevState;
                    });
                } else if (NA_VALUES.has(row.delegate2)) {
                    modifiedKey['hasErrors'] = true;
                    setUploadErrorReason(prevState => {
                        prevState.push({
                            field: 'Key Delegate 2',
                            keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                            error: 'Key delegate 2 alias: ' + row.delegate2 + ' is invalid for record.'
                        });
                        return prevState;
                    });
                } else if (!NA_VALUES.has(row.delegate2)) {
                    getOrSaveUser(row.delegate2, (response) => {
                        userMap[row.delegate2] = response;
                        del2['name'] = response.userName;
                    }, (response) => {
                        userMap[row.delegate2] = response.user;
                        del2['name'] = response.user.userName;
                    }, () => {
                        invalidUserMap.add(row.delegate2);
                        modifiedKey['hasErrors'] = true;
                        setUploadErrorReason(prevState => {
                            prevState.push({
                                field: 'Key Delegate 2',
                                keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                                error: 'Key delegate 2 alias: ' + row.delegate2 + ' is invalid for record.'
                            });
                            return prevState;
                        });
                    });
                    await delay(5000);
                } else {
                    del2['name'] = row.delegate2Name !== undefined ? row.delegate2Name : '';
                }
                del2['alias'] = row.delegate2;
                del2['rank'] = 2;
                del2['confirmationStatus'] = 'CONFIRMED';
                modifiedKey['keyDelegates'].push(del2);
            } else if (row['delegate2Name'] !== undefined && row['delegate2Name'] !== "") {
                modifiedKey['hasErrors'] = true;
                setUploadErrorReason(prevState => {
                    prevState.push({
                        field: 'Key Delegate 2',
                        keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                        error: 'Key delegate 2 alias: record is missing while key delegate 2 name "' + row['delegate2Name'] + '" is supplied'
                    });
                    return prevState;
                });
            }
            modifiedKey['keyCriteria'] = {};
            Object.keys(row).forEach((item) => {
                if(!STANDARD_FIELDS.has(item) && row[item] !== null && row[item] !== undefined && row[item] !== "") {
                    if (attributes[item].attributeValues !== undefined && attributes[item].attributeValues.indexOf(row[item]) > -1) {
                        modifiedKey.keyCriteria[item] = row[item];
                        delete coreAttributesForCurrentKey[item];
                    } else {
                        modifiedKey['hasErrors'] = true;
                        setUploadErrorReason(prevState => {
                            prevState.push({
                                field: attributes[item].attributeName,
                                keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                                error: attributes[item].attributeName + ': ' + row[item] + ' is invalid for record.'
                            });
                            return prevState;
                        });
                    }
                }
            });

            if (Object.keys(coreAttributesForCurrentKey).length > 0) {
                modifiedKey['hasErrors'] = true;
                setUploadErrorReason(prevState => {
                    prevState.push({
                        field: Object.values(coreAttributesForCurrentKey).join(", "),
                        keyId: !('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined ? '-' : row['keyId'],
                        error: 'Mandatory attributes ' + Object.values(coreAttributesForCurrentKey).join(", ") + ': missing values for record.'
                    });
                    return prevState;
                });
            }
            modifiedKey['keyConfirmed'] = true;
            modifiedKey['active'] = true;

            if (!('keyId' in row) || row['keyId'] === '' || row['keyId'] === undefined) {
                validatedKeys['createKeys'].push(modifiedKey);
            } else {
                validatedKeys['updateKeys'].push(modifiedKey);
            }
        }

        return validatedKeys;
    }

    return (
        <Container>
            <Importer
                assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                restartable={false} // optional, lets user choose to upload another file when import is complete
                chunkSize='1'
                skipEmptyLines='greedy'
                onStart={({ file, preview, fields, columnFields }) => {
                    // optional, invoked when user has mapped columns and started import
                    console.log('Started');
                    console.log(preview);
                    console.log(columnFields);
                    console.log(fields);
                }}
                processChunk={async (rows, { startIndex }) => {
                    // required, may be called several times
                    // receives a list of parsed objects based on defined fields and user column mapping;
                    // (if this callback returns a promise, the widget will wait for it before parsing more data)
                    const validatedRows = await setupKeysToCreateOrUpdate(rows);

                    setTotalCreateRows(prevState => prevState + validatedRows["createKeys"].length);

                    setTotalUpdateRows(prevState => prevState + validatedRows["updateKeys"].length);

                    const keysToCreate = validatedRows["createKeys"].filter((key) => !key.hasErrors);
                    const keysToUpdate = validatedRows["updateKeys"].filter((key) => !key.hasErrors);

                    if (keysToCreate.length > 0) {
                        await KeyService.bulkCreateKeysFromImport(keysToCreate, processId, user.userAlias).then(
                            response => {
                                setTotalCreatedKeys(prevState => {
                                    return prevState + response?.createSuccessfulKeys?.keys?.length;
                                });

                                // create validation error for each failed key
                                if (response.createFailedKeys?.keys?.length > 0) {
                                    for (const failedKey of response.createFailedKeys.keys) {
                                        setUploadErrorReason(prevState => {
                                            prevState.push({
                                                field: "Key Criteria",
                                                keyId: "-",
                                                error: failedKey.errorMessage
                                            });
                                            return prevState;
                                        });
                                    }
                                }
                            })
                            .catch(err => {
                                setCreateError(true);
                                console.log("Error while creating keys: ", err);
                            });
                    }

                    if (keysToUpdate.length > 0) {
                        await KeyService.bulkUpdateKeysFromImport(keysToUpdate, processId, user.userAlias).then(
                            response => {
                                setTotalUpdatedKeys(prevState => {
                                    return prevState + response?.updateSuccessfulKeys?.keys?.length;
                                });

                                // create validation error for each failed key
                                if (response.updateFailedKeys?.keys?.length > 0) {
                                    for (const failedKey of response.updateFailedKeys.keys) {
                                        setUploadErrorReason(prevState => {
                                            prevState.push({
                                                // validation error can be about Key ID or Key Criteria for update rows
                                                field: failedKey.errorMessage.substring(0, failedKey.errorMessage.indexOf(":")),
                                                keyId: failedKey.keyId,
                                                error: failedKey.errorMessage
                                            });
                                            return prevState;
                                        });
                                    }
                                }
                            })
                            .catch(err => {
                                setUpdateError(true);
                                console.log("Error while updating keys: ", err);
                            });
                    }
                }}
                onComplete={async ({ file, preview, fields, columnFields }) => {
                    let msg = "";
                    if (totalCreateRows > 0) {
                        // something went wrong while creating keys
                        if (createError){
                            msg += `Something went wrong while processing request to create keys from file `
                                + `${file.name}. `;
                            // at least 1 create row passed validation
                        } else {
                            msg += `${totalCreatedKeys}/${totalCreateRows} keys were successfully created. `;
                        }
                    }
                    if (totalUpdateRows > 0) {
                        // something went wrong while updating keys
                        if (updateError){
                            msg += `Something went wrong while processing request to update keys from file `
                                + `${file.name}, error.`;
                        } else {
                            msg += `${totalUpdatedKeys}/${totalUpdateRows} keys were successfully updated.`;
                        }
                    }

                    const notAllKeysUploaded = totalCreatedKeys !== totalCreateRows || totalUpdatedKeys !== totalUpdateRows;

                    updateMessage(msg, notAllKeysUploaded ? "error" : "success", false);
                }}
                onClose={({ file, preview, fields, columnFields }) => {
                    // optional, if this is specified the user will see a "Finish" button after import is done,
                    // which will call this when clicked
                    setReDirect(true);
                }}
            >
                {
                    Object.values(attributes).map((attribute) => {
                        attributeNameMap[attribute.attributeName] = attribute.attributeId;
                        return (
                            <ImporterField name={attribute.attributeId} label={attribute.attributeName} optional />
                        )
                    })
                }
                <ImporterField name="keyId" label="Key Id" optional />
                <ImporterField name="assignee" label="Assignee Alias" />
                <ImporterField name="assigneeName" label="Assignee Name" optional />
                <ImporterField name="delegate1" label="Delegate 1 Alias" optional />
                <ImporterField name="delegate1Name" label="Delegate 1 Name" optional />
                <ImporterField name="delegate2" label="Delegate 2 Alias" optional />
                <ImporterField name="delegate2Name" label="Delegate 2 Name" optional />
            </Importer>
            { reDirect ? (<Redirect push to="/bulk-edit-keys"/>) : null }

            <Table columnDefinitions={columnDefinitions}
                   items={uploadErrorReason}
                   loadingText="Loading validation errors"
                   header={<Header>{`Found ${uploadErrorReason.length}`} errors during validation</Header>}
                   wrapLines
            />
        </Container>
    );
}
