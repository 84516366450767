import {Col, Row} from "react-bootstrap";
import {FormField, Input, Select} from "@amzn/awsui-components-react";
import React, {useState} from "react";

/**
 * Component for the each individual edit in the Manage Responsibility Matrix
 *
 * @param props properties needed to render the page
 * @returns {JSX.Element}
 * @constructor
 */
export default function RACIMatrixAttributeEdit(props) {

    const bulkEditOptions = props.bulkEditOptions;
    const actionOptions = props.actionOptions;
    const selectedBulkEditLabels = props.selectedBulkEditLabels;
    const setSelectedBulkEditLabels= props.setSelectedBulkEditLabels;
    const selectedBulkEditValues= props.selectedBulkEditValues;
    const setSelectedBulkEditValues= props.setSelectedBulkEditValues;
    const selectedActions= props.selectedActions;
    const setSelectedBulkActions= props.setSelectedActions;
    const attributes = props.attributes;
    const index = props.index;

    const [bulkEditValueOptions, setBulkEditValueOptions] = useState("");
    const [selectedAction,setSelectedAction] = useState("");
    const [selectedBulkEditLabel,setSelectedBulkEditLabel] = useState(selectedBulkEditLabels[index]);
    const [selectedBulkEditValue,setSelectedBulkEditValue] = useState("");
    const [selectedBulkEditDropdownValue,setSelectedBulkEditDropdownValue] = useState("");
    const [isTextBox,setIsTextBox] = useState(true);
    const EDIT_TEXT = "Edit";

      const setSelectedLabels = (value) =>{

          if(value['label'] === "Assignee" || value['label'] === "Delegate 1" || value['label'] === "Delegate 2")
              setIsTextBox(true);

          else
          {
              setIsTextBox(false);
              let bulkSelectAttribute = Object.values(attributes).filter(elem => elem.attributeName === value['label'])[0];
              // sort bulk edit dropdown values in alphabetical order
              let blkEditValueOptions = bulkSelectAttribute.attributeValues.map(elem => ({label: elem, value: elem}))
                  .sort((a, b) =>
                  {
                      let l =  a.value.toLowerCase(), r = b.value.toLowerCase();
                      if (l < r) { return -1; }
                      if (l > r) { return 1; }
                      return 0;
                  }
                );
              setBulkEditValueOptions(blkEditValueOptions);
          }
          setSelectedBulkEditLabel(value);
          let labels = selectedBulkEditLabels;
          labels[index] = value;
          setSelectedBulkEditLabels(labels);
          setSelectedBulkEditDropdownValue("");
      }

    const setSelectedValues = (value) =>{
          setSelectedBulkEditValue(value);
        let values = selectedBulkEditValues;
        values[index] = value;
        setSelectedBulkEditValues(values);
    }
    const setSelectedDropdownValues= (value) =>{
        setSelectedBulkEditDropdownValue(value);
        let values = selectedBulkEditValues;
        values[index] = value.label;
        setSelectedBulkEditValues(values);
    }
    const setSelectedActions = (value) =>{
          setSelectedAction(value);
        let actions = selectedActions;
        actions[index] = value;
        setSelectedBulkActions(actions);
    }

    return(

        <Row   key={index} className="py-2 px-lg-2 d-flex  justify-content-md-left ">
            <Col id="select-value-label-col" lg={1} >
                <FormField id="select-value-label" className="pt-2"
                           label="Select Value"/>
            </Col>

            <Col lg={2} >
                <Select id = "bulk-edit-select-value-dropdown"
                        placeholder="Not Specified"
                        className="pt-1 pb-2"
                        selectedOption={selectedBulkEditLabel}
                        options={bulkEditOptions}
                        onChange={(event) => setSelectedLabels(event.detail.selectedOption)}
                        autoComplete={false}/>
            </Col>
            {
                 (selectedBulkEditLabel.label === "Delegate 1" || selectedBulkEditLabel.label === "Delegate 2") ?
                    <React.Fragment>
                        <Col id="bulk-edit-select-action-col" lg={2} className={"d-flex justify-content-end px-lg-2"}>
                            <FormField id="bulk-edit-select-action-label"className="pt-2"
                                       label="Select Action  "/>
                        </Col>

                        <Col lg={2} >
                            <Select id="bulk-edit-select-action-dropdown"
                                    placeholder="Not Specified"
                                    className="pt-1 pb-2"
                                    selectedOption={selectedAction}
                                    options={actionOptions}
                                    onChange={(event) => setSelectedActions(event.detail.selectedOption)}
                                    autoComplete={false}/>
                        </Col>
                    </React.Fragment>
                 :undefined
            }
            { !isTextBox?
                <>
                    <Col id="bulk-edit-enter-new val-col" lg={2} className={"d-flex justify-content-end px-lg-2"} >
                        <FormField id="bulk-edit-enter-new val-label" className="pt-2"
                                   label="Select New Value"/>
                    </Col>


                    <Col lg={2} >
                        <Select id="bulk-edit-select-action-dropdown"
                                placeholder="Not Specified"
                                className="pt-1 pb-2"
                                selectedOption={selectedBulkEditDropdownValue}
                                options={bulkEditValueOptions}
                                onChange={(event) => setSelectedDropdownValues(event.detail.selectedOption)}
                                autoComplete={false}/>
                    </Col>
                </>
                :
                <>
                    {
                        selectedBulkEditLabel.label === "Assignee" ||  selectedAction.label === EDIT_TEXT ||selectedBulkEditLabel.label === "" ?
                            <>
                                <Col id="bulk-edit-enter-new val-col" lg={2} className={"d-flex justify-content-end px-lg-2"} >
                                    <FormField id="bulk-edit-enter-new val-label" className="pt-2"
                                               label="Enter New Alias "/>
                                </Col>
                                <Col lg={2} >

                                    <Input id="bulk-edit-enter-new val-input"
                                           className="pt-1 pb-2"
                                           type='text'
                                           onChange={({detail}) => setSelectedValues(detail.value)}
                                           value={selectedBulkEditValue}/>
                                </Col>
                            </>
                            :undefined
                    }
                </>
            }
        </Row>
    )
}

