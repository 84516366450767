import React, {useState, useEffect} from "react";
import {FormField, Select} from "@amzn/awsui-components-react";
import {Col, Row,Button} from "react-bootstrap";
import "../assets/css/components/AttributeFilter.css";

export default function AttributeFilter(props) {
    const setSelectedValues = props.setSelectedValues;
    const selectedValues = props.selectedValues;
    const attribute = props.attribute;
    const [selectedValue, setSelectedValue] = useState(props.selectedValue);
    const mode = props.mode;
    const index =props.index;

    /**
     * useEffect hook to update the selected value for the attribute
     */
     useEffect(() => {
        setSelectedValue(props.selectedValue);
    }, [props.selectedValue]);

    const updateAttributeValue = function (option){
        setSelectedValue(option);
        let values = selectedValues;
        values[attribute.attributeId] = option.value;
        setSelectedValues(values);
    }

    const sortAttributeValues = (attributeValues) => {
        return Object.values(attributeValues).sort(function(a, b) {

            let l =  a.toLowerCase(), r = b.toLowerCase();

            if (l < r) { return -1; }
            if (l > r) { return 1; }
            return 0;
        });
    }

    const getAttributeName = () => {
        return attribute.coreAttribute ? attribute.attributeName + "*" : attribute.attributeName;
    }

    return (
        <Row  className="p-1 mb-3 justify-content-md-center row-control">
            <Col lg={2}
                 className={`d-flex justify-content-center ${props.classNamePrefix}-single-select-label-wrapper`}>
                <FormField className="pt-2 px-2 fw-bold"
                           id = {"attributeName-" + index}
                           label={getAttributeName()}/>
            </Col>
            <Col lg={2} className={`justify-content-center ${props.classNamePrefix}-single-select-filter-wrapper`}>
                <Select placeholder="Not Specified"
                        className="pt-1 pb-2"
                        id ={"attributeSelectDropdown-" + attribute.attributeName.replace(/\s/g, "")}
                        selectedOption={selectedValue}
                        options={sortAttributeValues(attribute.attributeValues).map(elem => ({attribute: attribute, value: elem}))}
                        onChange={(event) => updateAttributeValue(event.detail.selectedOption)}
                        autoComplete={false}
                        disabled={mode==="view"}/>
            </Col>
            <Col lg={1} className={`justify-content-center pt-1 ${props.classNamePrefix}-single-select-reset-wrapper`}>
                <Button
                    id= {"resetButton-" + index}
                        variant="normal"
                        className="btn btn-primary checkpoint-button"
                        onClick={() => updateAttributeValue("")}
                        autoComplete={false}>Reset
                </Button>
            </Col>
        </Row>
    )
}