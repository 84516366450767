import React, { useState } from 'react';
import {FormField, Input, Textarea, Select} from "@amzn/awsui-components-react";
import {Col, Row} from "react-bootstrap";
import "../assets/css/components/AdditionalInfo.css";

/**
 * Component to render the additional info fields: Update Reason dropdown, Notes input, Sim Ticket Id input.
 * Formalized them under a single component for reusability across multiple views: Key creation, Manage Responsibility Matrix, Process.
 *
 * @props - Ref's to all the fields in the component are expected to be passed down to prevent unnecessary re-render of the parent component, while retaining the 
 * updates on any / all of the fields present over here.
 */
const AdditionalInfo = (props) => {
    const [simTicketId, setSimTicketId] = useState("");
    const [notes, setNotes] = useState("");
    const updateReasonOptions = props.updateReasons;
    const [updateReasonSelectedOption, setUpdateReasonSelectedOption] = useState("Select a reason");

    /**
     * Handle the change in Sim Ticket Id input field. Update the parent component's Ref, if provided
     * A Maximum of 20 characters are allowed for sim ticket field
     */
    const handleSimTicketIdChange = (event) => {
        const value = event.detail.value.substr(0, 20);
        setSimTicketId(value);
        if(props.simTicketIdRef) {
            props.simTicketIdRef.current = value;
        }
    };

    /**
     * Handle the change in Notes text-area field. Update the parent component's Ref, if provided
     * A maximum of 200 characters are allowed for notes field
     */
    const handleNotesChange = (event) => {
        const value = event.detail.value.substr(0, 200);
        setNotes(value);
        if(props.notesRef) {
            props.notesRef.current = value;
        }
    };

    /**
     * Handle the change in Update Reason selection. Update the parent component's Ref, if provided
     */
    const handleUpdateReasonOptionChanged = (event) => {
        const value = event.detail.selectedOption.value;
        setUpdateReasonSelectedOption(value);
        if(props.updateReasonRef) {
            props.updateReasonRef.current = value;
        }
    };

    return (
            <Row  className={`p-1 ${props.marginBottom} row-control ${props.justifyCenter ? "justify-content-md-center" : ""}`}>
                <Col lg={4} className={`d-flex align-items-center ${props.justifyCenter ? "justify-content-md-center" : ""}`}>
                    <FormField className="pt-2 px-2 fw-bold additional-info-labels"
                                label="Update Reason"/>
                    <Select
                        type='text'
                        className = "pt-2 px-4 justify-content-md-left additional-info-notes-wrapper"
                        onChange = {handleUpdateReasonOptionChanged}
                        selectedOption = {{label: updateReasonSelectedOption, value: updateReasonSelectedOption}}
                        options = {updateReasonOptions.map(val =>  {return {label: val, value: val}} )}
                        id="additionalInfoUpdateReasonSelect"
                        key= "AdditionalInfo-UpdateReason-Select"
                    />
                </Col>

                <Col lg={4} className={`d-flex align-items-center ${props.justifyCenter ? "justify-content-md-center" : ""}`}>
                    <FormField className="pt-2 px-2 fw-bold additional-info-labels"
                                    label="Sim Ticket Id"/>
                    <Input
                        type='text'
                        className = "pt-2 px-4 justify-content-md-left"
                        value = {simTicketId}
                        onChange = {handleSimTicketIdChange}
                        autoComplete = {false}
                        placeholder = "Sim Ticket Id"
                        id="additionalInfoSimTicketIdInput"
                        key= "AdditionalInfo-SimTicketId-Input"
                    />
                </Col>

                <Col lg={4} className={`d-flex align-items-center ${props.justifyCenter ? "justify-content-md-center" : ""}`}>
                    <FormField className="pt-2 px-2 fw-bold additional-info-labels"
                                    label="Notes"/>
                    <Textarea
                        type='text'
                        className = "pt-2 px-4 justify-content-md-left additional-info-notes-wrapper"
                        value = {notes}
                        onChange = {handleNotesChange}
                        autoComplete = {false}
                        placeholder = "Notes"
                        id="additionalInfoNotesInput"
                        key= "AdditionalInfo-Notes-Input"
                    />
                </Col>
            </Row> 
    );
};


export default AdditionalInfo;